import {Pipe, PipeTransform} from '@angular/core';
import {ProjectStatus} from "../../core/model/project/project-status.enum";

@Pipe({
  name: 'enumLabel',
})
export class EnumLabelPipe implements PipeTransform {
  transform(value: ProjectStatus): string {
    switch (value) {
      case ProjectStatus.Complete:
        return 'Complete';
      case ProjectStatus.Active:
        return 'Active';
      case ProjectStatus.OnHold:
        return 'On Hold';
      default:
        return ''; // Handle other cases if necessary
    }
  }
}
