import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import * as moment from "moment";
import {AuthService} from "../../../core/service/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {timeOffCategoryMap} from '../../../core/constant/time-off-categories.constant';
import {TimeOffService} from "../../../core/service/time-off.service";
import {Router} from "@angular/router";
import {Role} from "../../../core/model/employee/role.enum";
import {Subject, Subscription, switchMap, takeUntil} from "rxjs";
import {TimeOffCategory} from "../../../core/model/time-off/time-off-category.enum";
import {TimeOffResponseModel} from "../../../core/model/time-off/time-off-response.model";
import {timeOffOverlapValidator} from 'src/app/core/validators/time-off-overlap.validator';
import {ErrorMessages} from "../../../core/constant/error-messages.constant";
import {SafeUrl} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ComponentType} from "@angular/cdk/overlay";
import {TimeOffRequestModel} from "../../../core/model/time-off/time-off-request.model";
import {SnackbarMessageComponent} from "../snackbar-message/snackbar-message.component";
import {SnackbarErrorMessageComponent} from "../snackbar-error-message/snackbar-error-message.component";
import {DeleteDialogComponent} from "../delete-dialog/delete-dialog.component";

@Component({
  selector: 'app-add-event-popup',
  templateUrl: './add-event-popup.component.html',
  styleUrls: ['./add-event-popup.component.css'],
})
export class AddEventPopupComponent implements OnInit, OnDestroy {
  public currentUserId: number;
  public currentUserRole: Role;
  public profilePicture: string | SafeUrl = '';
  public isView: boolean;
  public isAllDay = false;
  public timeOffCategoryMap = timeOffCategoryMap;
  public eventForm: FormGroup;
  public isDisabled: boolean = false;
  private _unsubscribe$: Subject<void> = new Subject();

  event: TimeOffRequestModel = new TimeOffRequestModel();
  editPopup: boolean = false;
  timeOffCategorySubscription: Subscription | undefined;
  allTimeOffsByUserId: Array<TimeOffResponseModel> = [];
  disabledAllDayCategories: Array<TimeOffCategory> = [
    TimeOffCategory.VACATION,
    TimeOffCategory.BUSINESS_TRIP,
    TimeOffCategory.SICK_LEAVE,
  ];

  private readonly ONE_DAY = 1;
  private readonly START_DATE_TIME = 'startDateTime';
  private readonly END_DATE_TIME = 'endDateTime';
  private readonly ERROR_PANEL = 'error-message';
  private readonly ERROR_MESSAGE = 'The new event you are trying to add overlaps with an existing event.';
  private readonly SUCCESS_PANEL = 'green-snackbar';
  private readonly CREATE_SUCCESS_MESSAGE = 'Event successfully created․';
  private readonly EDIT_SUCCESS_MESSAGE = 'Event successfully updated․';

  constructor(
    public dialogRef: MatDialogRef<AddEventPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private fb: FormBuilder,
    private timeOffService: TimeOffService,
    private router: Router,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {
    this.currentUserId = this.authService.getCurrentUser().id;
    this.currentUserRole = this.authService.getCurrentUserRole()
    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });
    this.eventForm = this.fb.group({
      timeOffCategory: [null, [Validators.required]],
      compensatory: [false],
      isAllDay: [{
        value: false,
        disabled: this.data.timeOff?.timeOffCategory != null
          && typeof this.data.timeOff?.timeOffCategory === 'string'
          && this.disabledAllDayCategories.includes(this.data.timeOff?.timeOffCategory),
      }],
      startDateTime: [null, [Validators.required,]],
      endDateTime: [null, [Validators.required,]],
      reason: ["",[Validators.required,Validators.maxLength(500)]],
    }, {
      validators: [timeOffOverlapValidator(() => this.allTimeOffsByUserId, this.currentUserId, this.data.timeOff?.requestId)],
    });
  }

  ngOnInit(): void {
    this.getAllTimeOffs();

    this.initializeIfSelectedOneDate();
    this.initializeIfSelectedManyDates();
    this.initializeIfSelectedEvent();
    this.markAsTouchedIfNotEmpty();
    this.allDayValueChanges()

    if (this.data) {
      this.isView = this.data.isView;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this.timeOffCategorySubscription?.unsubscribe();
  }

  public onSubmit(): void {

    this.markAllFieldsAsTouched();

    if (!this.eventForm.valid) {
      return;
    }

    this.isDisabled = true;

    const controls = this.eventForm.controls;
    this.event.timeOffCategory = controls["timeOffCategory"].value;
    this.event.reason = controls["reason"].value;
    this.event.compensatory = controls["compensatory"].value;
    this.event.isAllDay = controls["isAllDay"].value;
    if (controls["isAllDay"].value) {
      this.event.startDateTime = moment(controls[this.START_DATE_TIME].value).startOf("day").toDate();
      this.event.endDateTime = moment(controls[this.END_DATE_TIME].value).endOf("day").toDate();
    } else {
      this.event.startDateTime = moment(controls[this.START_DATE_TIME].value).toDate();
      this.event.endDateTime = moment(controls[this.END_DATE_TIME].value).toDate();
    }

    this.event.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (this.editPopup) {
      this.editTimeOff();
    } else {
      this.createTimeOff();
    }
  }

  public closeDialog(): void {
    this.dialogRef.close('CLOSE');
  }

  public edit(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      timeOff: this.data.timeOff,
      timeOffs: this.data.timeOffs,
      isView: false
    };
    dialogConfig.width = "570px";
    dialogConfig.disableClose = true;
    this.matDialog.open(AddEventPopupComponent, dialogConfig).afterClosed().subscribe({
      next: () => {
        this.dialogRef.close();
      }
    });
  }

  public onCategoryChange(selectedCategory: TimeOffCategory): void {
    if (this.disabledAllDayCategories.includes(selectedCategory) || selectedCategory === TimeOffCategory.WORKING_REMOTELY || selectedCategory === TimeOffCategory.TIME_OFF) {
      this.eventForm.patchValue({ isAllDay: true });
    }
  }

  public navigateToProfile(): void {
    if (this.data.employee.fullName.includes('admin')) return;
    this.router.navigate([`/profile/${this.event.employeeId}/personal`], {state: {reload: true}})
      .then().catch();
  }

  protected getReasonStyles(): string {
    const isStartDateTimeAndEndDateTimeTouched = this.eventForm.get(this.START_DATE_TIME)?.touched && this.eventForm.get(this.END_DATE_TIME)?.touched;
    const transition03s = 'transition: all 0.3s;';
    const isEventFormHasTimeDiffAndDateOverlapError = this.eventForm.hasError('timeDifference') && this.eventForm.hasError('dateOverlap');
    const isEventFormHasTimeDiffOrDateOverlapError = this.eventForm.hasError('timeDifference') || this.eventForm.hasError('dateOverlap');

    if (this.editPopup || !isStartDateTimeAndEndDateTimeTouched) {
      return '';
    }

    if (isEventFormHasTimeDiffAndDateOverlapError) {
      return `margin-top: 40px; ${transition03s}`;
    }

    return isEventFormHasTimeDiffOrDateOverlapError ? `margin-top: 20px; ${transition03s}` : transition03s;
  }

  private markAsTouchedIfNotEmpty() {
    if (this.eventForm.get(this.START_DATE_TIME)?.value) {
      this.eventForm.get(this.START_DATE_TIME)?.markAsTouched();
    }
    if (this.eventForm.get(this.END_DATE_TIME)?.value) {
      this.eventForm.get(this.END_DATE_TIME)?.markAsTouched();
    }
  }

  private markAllFieldsAsTouched() {
    Object.keys(this.eventForm.controls).forEach(field => {
      const control = this.eventForm.get(field);
      control?.markAsTouched({onlySelf: true});
    });
  }

  private createTimeOff(): void {
    this.event.employeeId = this.currentUserId;
    this.timeOffService.create(this.event).subscribe({
      next: () => {
        this.closeDialog();
        this.isDisabled = true;
        this.showSnackMessage(SnackbarMessageComponent, this.CREATE_SUCCESS_MESSAGE, this.SUCCESS_PANEL)
      }, error: (err) => {
        if (err.status === 409) {
          this.showSnackMessage(SnackbarErrorMessageComponent, this.ERROR_MESSAGE, this.ERROR_PANEL)
        }
        this.closeDialog();
      }
    });
  }

  private editTimeOff(): void {
    this.timeOffService.edit(this.event).subscribe({
      next: () => {
        this.closeDialog();
        this.isDisabled = true;
        this.showSnackMessage(SnackbarMessageComponent, this.EDIT_SUCCESS_MESSAGE, this.SUCCESS_PANEL)
      }, error: (err) => {
        if (err.status === 409) {
          this.showSnackMessage(SnackbarErrorMessageComponent, this.ERROR_MESSAGE, this.ERROR_PANEL)
        }
        this.closeDialog()
      }
    })
  }

  public deleteTimeOff(requestId: number) {
    const dialogRef = this.matDialog.open(DeleteDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this event?',
      },
    });

    dialogRef.afterClosed().pipe(takeUntil(this._unsubscribe$),
      switchMap((result) => {
        if (result === true) {
          return this.timeOffService.delete(requestId);
        }
        return [];
      })
    ).subscribe({
      next: () => {
        this.closeDialog();
      }
    });
  }

  private initializeIfSelectedOneDate() {
    if (this.data.timeOff?.date) {
      const today = new Date(this.data.timeOff?.date)
      const selectedDate = this.data.timeOff?.date.getDate() === new Date().getDate() ? today : new Date(this.data.timeOff?.date);
      this.eventForm.controls[this.START_DATE_TIME].setValue(moment(selectedDate));
      const endDate = new Date(selectedDate);
      endDate.setMinutes(selectedDate.getMinutes() + 1);
      this.eventForm.controls[this.END_DATE_TIME].setValue(moment(endDate));
      this.eventForm.updateValueAndValidity();
    }
  }

  private initializeIfSelectedManyDates() {
    if (this.data.timeOff?.end && this.data.timeOff?.start) {
      const startDate: Date = new Date(this.data.timeOff?.start);
      const endDate: Date = new Date(this.data.timeOff?.end);
      endDate.setDate(endDate.getDate() - this.ONE_DAY);
      this.eventForm.controls[this.START_DATE_TIME].setValue(moment(startDate).startOf('day'));
      this.eventForm.controls[this.END_DATE_TIME].setValue(moment(endDate).startOf('day'));
      this.eventForm.updateValueAndValidity();
    }
  }

  private initializeIfSelectedEvent(): void {
    const controls = this.eventForm.controls;

    if (this.data.timeOff?.requestId) {
      const startDate = new Date(this.data.timeOff?.start);
      const endDate = new Date(this.data.timeOff?.end);
      this.editPopup = true;

      controls["timeOffCategory"].setValue(this.data.timeOff?.timeOffCategory);
      controls["reason"].setValue(this.data.timeOff?.reason);
      controls["compensatory"].setValue(this.data.timeOff?.compensatory);
      controls["isAllDay"].setValue(this.data.timeOff?.isAllDay);
      controls[this.START_DATE_TIME].setValue(moment(startDate));
      controls[this.END_DATE_TIME].setValue(moment(endDate));
      this.event.requestId = this.data.timeOff?.requestId;
      this.event.requestStatus = this.data.timeOff?.requestStatus;
      this.event.employeeId = this.data.timeOff?.employee.id;
      this.event.timeOffCategory = this.data.timeOff?.timeOffCategory
      this.isView = this.data.timeOff?.isView;
      this.eventForm.updateValueAndValidity();
    }
  }

  private showSnackMessage(showSnackComponent: ComponentType<SnackbarMessageComponent | SnackbarErrorMessageComponent>,
                           message: string, panelClass: string) {
    this.matSnackBar.openFromComponent(showSnackComponent, {
      duration: 4000,
      data: {message: message},
      panelClass: [panelClass],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  private getAllTimeOffs(): void {
    this.allTimeOffsByUserId = this.data.timeOffs || []
    this.eventForm.updateValueAndValidity();
  }

  private allDayValueChanges() {
    this.eventForm.get("isAllDay")?.valueChanges.subscribe((value) => {
      if (!value && this.eventForm.controls["timeOffCategory"].value !== TimeOffCategory.TIME_OFF) {
        this.eventForm.controls[this.START_DATE_TIME].setValue(moment(this.eventForm.controls[this.START_DATE_TIME].value).startOf('day'))
        this.eventForm.controls[this.END_DATE_TIME].setValue(moment(this.eventForm.controls[this.END_DATE_TIME].value).endOf('day'))
      }
    })
  }

  protected readonly TimeOffCategory = TimeOffCategory;
  protected readonly Role = Role;
  protected readonly ErrorMessages = ErrorMessages;
}
