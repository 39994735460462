import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadcrumbHomeComponent} from "./components/breadcrumb-home/breadcrumb-home.component";
import {CategoryScrollComponent} from "./components/caregory-scroll/category-scroll.component";
import {DateRangeCalendarComponent} from "./components/date-range-calendar/date-range-calendar.component";
import {HandbookTabsComponent} from "./components/handbook-tabs/handbook-tabs.component";
import {StarRatingComponent} from "./components/star-rating/star-rating.component";
import {
  UserDiscordStatisticsChartComponent
} from "./components/user-discord-statistic-chart/user-discord-statistics-chart.component";
import {BadgeImagePipe} from "./pipes/badge-image.pipe";
import {EnumLabelPipe} from "./pipes/enum.pipe";
import {HyphenToSpacePipe} from "./pipes/hyphen-to-space.pipe";
import {KeyValuePipe} from "./pipes/key-value.pipe";
import {ReplaceUnderscorePipe} from "./pipes/replace-underscore.pipe";
import {UserImagePipe} from "./pipes/user-image.pipe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgChartsModule} from "ng2-charts";
import {BadgeListComponent} from "./components/badge-list/badge-list.component";
import {NoWhitespaceDirective} from "./directives/no-whitespace-directive.validator";
import {BadgeAddComponent} from "./components/badge-add/badge-add.component";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {BadgeSendComponent} from "./components/badge-send/badge-send.component";
import {BadgeSendDialogComponent} from "./components/badge-send/popup/badge-send-dialog/badge-send-dialog.component";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatInputModule} from "@angular/material/input";
import {MenuZIndexDirective} from "./directives/menu-z-index.directive";
import {MatNativeDateModule} from "@angular/material/core";
import {RouterLink} from "@angular/router";
import {SnackbarMessageComponent} from "./components/snackbar-message/snackbar-message.component";
import { FormatCategoryPipe } from './pipes/format-category-pipe.pipe';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {SnackbarErrorMessageComponent} from "./components/snackbar-error-message/snackbar-error-message.component";


@NgModule({
  declarations: [
    BreadcrumbHomeComponent,
    CategoryScrollComponent,
    DateRangeCalendarComponent,
    HandbookTabsComponent,
    StarRatingComponent,
    UserDiscordStatisticsChartComponent,
    BadgeListComponent,
    BadgeAddComponent,
    BadgeSendComponent,
    BadgeSendDialogComponent,
    BadgeImagePipe,
    EnumLabelPipe,
    HyphenToSpacePipe,
    KeyValuePipe,
    ReplaceUnderscorePipe,
    UserImagePipe,
    NoWhitespaceDirective,
    MenuZIndexDirective,
    SnackbarMessageComponent,
    SnackbarErrorMessageComponent,
    FormatCategoryPipe,
    DeleteDialogComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIconModule,
        FontAwesomeModule,
        NgChartsModule,
        MatSelectModule,
        MatDialogModule,
        NgxMatSelectSearchModule,
        MatInputModule,
        RouterLink,
        MatTooltipModule
    ],
  exports: [
    BreadcrumbHomeComponent,
    CategoryScrollComponent,
    HandbookTabsComponent,
    StarRatingComponent,
    UserDiscordStatisticsChartComponent,
    BadgeListComponent,
    BadgeAddComponent,
    BadgeSendComponent,
    BadgeImagePipe,
    EnumLabelPipe,
    HyphenToSpacePipe,
    KeyValuePipe,
    ReplaceUnderscorePipe,
    UserImagePipe,
    NoWhitespaceDirective,
    MenuZIndexDirective,
    SnackbarMessageComponent,
    FormatCategoryPipe
  ]
})
export class SharedModule {
}
