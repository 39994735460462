import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BadgeService} from "../../../../../core/service/badge.service";
import {BadgeResponseModel} from "../../../../../core/model/badge/badge-response.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EmployeeService} from "../../../../../core/service/employee.service";
import {debounceTime, map, of, ReplaySubject, Subject, switchMap, takeUntil, tap} from "rxjs";
import {EmployeeBadgeRequestModel} from "../../../../../core/model/badge/employee-badge-request.model";
import {filter} from "rxjs/operators";
import {AuthService} from "../../../../../core/service/auth.service";
import {SearchDataModel} from "../../../../../core/model/search/search-data.model";
import {PageableModel} from "../../../../../core/model/pagination/pageable.model";
import {EmployeePageModel} from "../../../../../core/model/employee/employee-page.model";
import {EmployeeResponseForListModel} from "../../../../../core/model/employee/employee-response-for-list.model";
import {Role} from "../../../../../core/model/employee/role.enum";
import {EmployeeResponseModel} from "../../../../../core/model/employee/employee-response.model";
import {BadgeRoleModelEnum} from "../../../../../core/model/badge/badge-role-model-enum";
import {EmployeeInfoResponseModel} from "../../../../../core/model/employee/employee-info-response.model";


@Component({
  selector: 'app-badge-send-dialog',
  templateUrl: './badge-send-dialog.component.html',
  styleUrls: ['./badge-send-dialog.component.css']
})
export class BadgeSendDialogComponent implements OnInit, OnDestroy {
  public badge: BadgeResponseModel;
  public receivers: EmployeeInfoResponseModel[] = [];
  public filteredEmployees: ReplaySubject<EmployeeResponseForListModel[]> = new ReplaySubject<Array<EmployeeResponseForListModel>>(1);
  public searching = false;
  public sendBadgeForm: FormGroup;
  public isSubmitted = false;
  public search: string = "Search";
  private isManager: boolean = false
  private isLead: boolean = false
  showAllReceivers: boolean = false;

  private user: EmployeeResponseModel = new EmployeeResponseModel();

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<BadgeSendDialogComponent>,
    private badgeService: BadgeService,
    private employeeService: EmployeeService,
    private authService: AuthService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.badge = this.data;
  }

  ngOnInit(): void {
    this.user = this.authService.getCurrentUser()
    this.getIsManager()
    this.getIsLead()
    this.getReceiver()
    this.sendBadgeForm = this.fb.group({
      employee: new FormControl('',Validators.required),
      employeeFilter: new FormControl(''),
        comment: new FormControl('', [Validators.required, Validators.minLength(50), Validators.maxLength(200)]),
    });
    this.sendBadgeForm.controls["employeeFilter"].valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      takeUntil(this._unsubscribe$),
      debounceTime(200),
      switchMap(value => {
        if (value.length > 0) {
          const searchModel: SearchDataModel = new SearchDataModel('fullName', value, [], true);
          const pageable: PageableModel = new PageableModel(0);
         if ((this.isManager || this.isLead && this.user.role !== Role.ADMIN) && this.badge.badgeRole === BadgeRoleModelEnum.LEAD) {
            return this.employeeService.searchByManager(this.user.id, pageable, searchModel);
          }
          return this.employeeService.search(pageable, searchModel);
        }
        return of([])
      })
    ).pipe(
      // @ts-ignore
      map((employees: EmployeePageModel) => {
        const currentUserId: number = this.authService.getCurrentUser().id;
        return employees.content.filter((employee: { id: number; }) => employee.id != currentUserId);
      })
    ).subscribe((filteredEmployees: EmployeeResponseForListModel[]) => {
      this.searching = false;
      this.filteredEmployees.next(filteredEmployees);
    });
  }

  private getIsManager() {
    this.employeeService.isManager(this.user.id).subscribe(data => {
      this.isManager = data
    })
  }

  private getReceiver() {
    this.badgeService.getReceivers(this.badge.id).subscribe(data => {
      this.receivers = data
    })
  }

  private getIsLead() {
    this.employeeService.isLead(this.user.id).subscribe(data => {
      this.isLead = data
    })
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.sendBadgeForm.invalid || !this.sendBadgeForm.get("employee")?.value["id"]) {
      this.sendBadgeForm.get("employee")?.setErrors({ 'nonValidEmployee': true });
      return;
    }

    const sendBadge = new EmployeeBadgeRequestModel();
    sendBadge.badgeId = this.badge.id;
    sendBadge.comment = this.sendBadgeForm.get("comment")?.value || "";
    sendBadge.receiverId = this.sendBadgeForm.get("employee")?.value["id"] || "";

    this.badgeService.sendBadge(sendBadge)
      .subscribe({
        next: () => {
          this.closeDialog();
        },
        error: () => {
          this.closeDialog();
        }
      });
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  protected readonly SearchDataModel = SearchDataModel;
}
