import {Pipe, PipeTransform} from '@angular/core';
import {BadgeService} from "../../core/service/badge.service";
import {map, Observable} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'badgeImage'
})
export class BadgeImagePipe implements PipeTransform {
  constructor(
    private badgeService: BadgeService,
    private sanitizer: DomSanitizer
  ) {
  }

  transform(badgeId: number): Observable<any> {
    return this.badgeService.getImage(badgeId).pipe(
      map((data: Blob) => {

        const blob = new Blob([data], {type: 'image/jpeg'});
        const imageUrl = URL.createObjectURL(blob);
        return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      })
    );
  }
}
