import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {AuthResponseModel} from "../model/auth/auth-response.model";
import {EmployeeResponseModel} from "../model/employee/employee-response.model";
import {LoginCredentialsModel} from "../model/auth/login-credentials.model";
import {EmployeeVerifyModel} from "../model/auth/employee-verify.model";
import {Role} from "../model/employee/role.enum";
import {EmployeeForgotPasswordResponseModel} from "../model/employee/employee-forgot-password-response.model";
import {SendVerifyCodeResponseModel} from "../model/mail/send-verify-code-response.model";
import {EmailRequestModel} from "../model/mail/email-request.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly BASE_PATH = environment.apiURL;
  private readonly VERSION = environment.version;

  constructor(
    private http: HttpClient,
    private route: Router,
  ) {
  }

  auth(credentialsDto: LoginCredentialsModel): Observable<AuthResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}login`;
    return this.http.post<AuthResponseModel>(path, credentialsDto);
  }

  verify(verifyDto: EmployeeVerifyModel): Observable<AuthResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}verify`;
    return this.http.post<AuthResponseModel>(path, verifyDto);
  }

  changePassword(verifyDto: EmployeeVerifyModel): Observable<void> {
    const path = `${this.BASE_PATH}${this.VERSION}change-password`;
    return this.http.post<void>(path, verifyDto);
  }

  sendForgetPasswordVerifyCode(emailRequestModel: EmailRequestModel): Observable<SendVerifyCodeResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}send-verify-code`;
    return this.http.post<SendVerifyCodeResponseModel>(path, emailRequestModel);
  }

  setForgotPasswordToken(emailRequestModel: EmailRequestModel): Observable<EmployeeForgotPasswordResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}set-forgot-password-token`;
    return this.http.post<EmployeeForgotPasswordResponseModel>(path, emailRequestModel);
  }

  isVerifiedByEmail(requestDto: EmailRequestModel): Observable<boolean> {
    let path: string = `${this.BASE_PATH}${this.VERSION}is-verified`;
    return this.http.post<boolean>(path, requestDto);
  }

  saveToken(employee: AuthResponseModel): void {
    sessionStorage.setItem('token', employee.token);
    sessionStorage.setItem('currentUser', JSON.stringify(employee));
  }

  logout(): void {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('currentUser');
    this.route.navigate(['login']).then().catch();
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('currentUser')!);
  }

  isLoggedIn(): boolean {
    return !!(this.getToken());
  }

  updateCurrentUserProfilePicture(pic: string) {
    let currentUser: EmployeeResponseModel = this.getCurrentUser();
    currentUser.profilePicture = pic;
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  setCurrentUser(currentUser: AuthResponseModel): void {
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getCurrentUserRole(): Role {
    return this.getCurrentUser().role;
  }
}
