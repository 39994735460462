import {Component, OnInit, ViewChild} from '@angular/core';
import {BadgeService} from "../../../core/service/badge.service";
import {BadgeCategoryModel} from "../../../core/model/badge/badge-category.model";
import {BadgeResponseModel} from "../../../core/model/badge/badge-response.model";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BadgeSendDialogComponent} from "./popup/badge-send-dialog/badge-send-dialog.component";
import {Role} from "../../../core/model/employee/role.enum";
import {AuthService} from "../../../core/service/auth.service";
import {BadgeAddComponent} from "../badge-add/badge-add.component";
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {PageableModel} from "../../../core/model/pagination/pageable.model";
import {BadgeCategoryStatusEnum} from "../../../core/model/badge/badge-category-status.enum";
import {DeleteDialogComponent} from "../delete-dialog/delete-dialog.component";

@Component({
  selector: 'app-employee-badge-component-send',
  styleUrls: ['./badge-send.component.css'],
  templateUrl: './badge-send.component.html'
})
export class BadgeSendComponent implements OnInit{
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;

  public currentUserRole: string;
  public badgeCategories: BadgeCategoryModel[] = [];
  public badges: BadgeResponseModel[] = [];
  public selectedCategoryId = 0;
  public size: number = 21;
  public currentPage: number = 0;
  public totalPages: number;
  public moreButton: boolean = true;
  public lessButton: boolean = false;
  public totalElements: number;
  dialogRef: MatDialogRef<BadgeAddComponent> | undefined

  constructor(
    private badgeService: BadgeService,
    private dialog: MatDialog,
    private auth: AuthService,
  ) {
    this.currentUserRole = this.auth.getCurrentUserRole();
  }

  ngOnInit(): void {
    this.getAllBadgeCategories()
    const pageable = new PageableModel(this.currentPage, this.size);
    this.getAllBadges(this.selectedCategoryId, pageable)
  }

  public selectCategory(categoryId: number) {
    this.selectedCategoryId = categoryId;
    const pageable = new PageableModel(0, this.size);
    this.getAllBadges(this.selectedCategoryId, pageable)
  }

  public openSendBadgeDialog(badge: BadgeResponseModel) {
    if (badge.isAllowed){
      this.dialog.open(BadgeSendDialogComponent, {
        width: '700px',
        data: badge
      });
    }
  }

  protected readonly Role = Role;

  addBadges() {
    const config = {
      data: {badge: null},
      width: "700px"
    }
    this.dialog.open(BadgeAddComponent, config).afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          const pageable = new PageableModel(0, this.size);
          this.getAllBadges(this.selectedCategoryId, pageable)
          this.getAllBadgeCategories()
        }
      }
    })
  }

  archivedBadge(id: number): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this badge?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.badgeService.archivedBadge(id).subscribe(
          {
            next: () => {
              this.badges = this.badges.filter(badge => badge.id !== id);
              if (this.badges.length === 0) {
                const pageable = new PageableModel(0, this.size);
                this.getAllBadges(0, pageable)
                this.badgeCategories = this.badgeCategories.filter(category => category.id !== this.selectedCategoryId);
              }
            }, error() {
              console.log("Error")
            }
          }
        )
      }
    });
  }

  editBadge(badge: any) {
    const dialogRefConfig = {
      data: {badge},
      width: "700px",
    };

    this.dialog.open(BadgeAddComponent, dialogRefConfig)
      .afterClosed().subscribe({
      next: (result: any) => {
        if (result) {
          const pageable = new PageableModel(0, this.size);
          this.getAllBadges(this.selectedCategoryId, pageable);
        }
      }
    })
  }

  moreBadges() {
    if (this.currentPage < this.totalPages) {
      this.currentPage = this.currentPage + 1;
      if (this.currentPage === (this.totalPages - 1)) {
        this.moreButton = false
        this.lessButton = true
      }
      const pageable = new PageableModel(this.currentPage, this.size);
      this.badgeService.getAllByCategory(this.selectedCategoryId, pageable).subscribe((data) => {
        this.badges = [...this.badges, ...data.content];
      })
    }
  }

  lessBadges() {
    this.currentPage = 0;
    this.lessButton = false;
    this.moreButton = true
    const pageable = new PageableModel(0, this.size);
    this.getAllBadges(this.selectedCategoryId, pageable);
  }

  private getAllBadges(categoryId: number, pageable: PageableModel) {
    this.badgeService.getAllByCategory(categoryId, pageable).subscribe(data => {
      this.badges = data.content
      this.totalPages = data.totalPages;
      this.totalElements = data.totalElements;
    })
  }

  private getAllBadgeCategories() {
    this.badgeService.getAllBadgeCategories(BadgeCategoryStatusEnum.CATEGORIES_WITH_BADGE).subscribe(data => {
      this.badgeCategories = data
    })
  }
}
