import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {BadgeCategoryModel} from "../../../core/model/badge/badge-category.model";

@Component({
  selector: 'app-category-scroll',
  templateUrl: './category-scroll.component.html',
  styleUrls: ['./category-scroll.component.css']
})
export class CategoryScrollComponent implements AfterViewInit, AfterViewChecked {

  @ViewChild('wrapper') wrapper!: ElementRef<HTMLDivElement>;
  @Input() categories: Array<BadgeCategoryModel> = [];
  @Output() selectedCategory: EventEmitter<number> = new EventEmitter<number>();

  protected selectedCategoryId = 0;
  protected isScrollable = false;
  protected visibleItemsCount: number = 2;
  protected isTouching: boolean = false;
  private isDown = false;
  private startX: number;
  private scrollLeft: number;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.updateScrollNextButton();
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.updateScrollNextButton();
      this.cdr.detectChanges();
    });
  }

  public selectCategory(categoryId: number) {
    this.selectedCategoryId = categoryId;
    this.selectedCategory.emit(categoryId)
  }

  onMouseDown(event: MouseEvent): void {
    this.isDown = true;
    this.startX = event.pageX - (event.currentTarget as HTMLElement).offsetLeft;
    this.scrollLeft = (event.currentTarget as HTMLElement).scrollLeft;
  }

  onMouseLeave(): void {
    this.isDown = false;
  }

  onMouseUp(): void {
    this.isDown = false;
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDown) return;
    event.preventDefault();
    const x = event.pageX - (event.currentTarget as HTMLElement).offsetLeft;
    const walk = (x - this.startX) * 2;
    (event.currentTarget as HTMLElement).scrollLeft = this.scrollLeft - walk;
  }

  onTouchStart(event: TouchEvent) {
    const touch = event.touches[0];
    this.startX = touch.pageX - this.wrapper.nativeElement.offsetLeft;
    this.scrollLeft = this.wrapper.nativeElement.scrollLeft;
    this.isTouching = true;
  }

  onTouchMove(event: TouchEvent) {
    if (this.isTouching) {
      const touch = event.touches[0];
      const x = touch.pageX - this.wrapper.nativeElement.offsetLeft;
      const walk = (x - this.startX) * 2;
      this.wrapper.nativeElement.scrollLeft = this.scrollLeft - walk;
      event.preventDefault();
    }
  }

  onTouchEnd($event: TouchEvent) {
    this.isTouching = false;
  }

  scrollRight(): void {
    const wrapper = this.wrapper?.nativeElement;
    if (wrapper) {
      const scrollAmount = 300;
      wrapper.scrollTo({
        left: wrapper.scrollLeft + scrollAmount,
        behavior: 'smooth'
      });
      this.updateScrollNextButton();
    }
  }

  private updateScrollNextButton(): void {
    const wrapper = this.wrapper?.nativeElement;
    if (wrapper) {
      this.isScrollable = wrapper.scrollWidth > wrapper.clientWidth;
      if (wrapper.scrollLeft + wrapper.clientWidth >= wrapper.scrollWidth) {
        this.isScrollable = false;
      }
    }
  }
}
