import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../core/service/auth.service";
import {AuthResponseModel} from "../../core/model/auth/auth-response.model";
import {Router} from "@angular/router";
import {Role} from "../../core/model/employee/role.enum";
import {LoginCredentialsModel} from "../../core/model/auth/login-credentials.model";
import {Styles} from "../../core/constant/styles.constant";
import {CookieService} from "ngx-cookie-service";
import {map, Observable, startWith} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide = true;
  remember: boolean = false;
  cookieUsers: LoginCredentialsModel[] = [];
  filteredUsers: Observable<LoginCredentialsModel[]>;

  public readonly TRANSITION_05: string = Styles.generateTransition(0.5);
  public readonly MARGIN_TOP_30: string = Styles.generateMarginTop(30);

  protected showLoginErrorMessage: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: AuthService,
    private router: Router,
    private cookieService: CookieService,
  ) {
  }

  ngOnInit() {
    this.getCookieUsers()
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
    this.searchByEmail()
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.emailControl().errors || this.passwordControl().errors) {
      return;
    }

    const credentialsModel: LoginCredentialsModel = this.loginForm.getRawValue();
    this.service.auth(credentialsModel)
      .subscribe({
        next: (employee: AuthResponseModel) => {
          this.handleLoginResponse(employee);
          this.saveInCookie(credentialsModel)
        },
        error: () => {
          this.handleLoginErrorResponse();
        }
      });
  }

  emailControl(): AbstractControl {
    return this.f['email'];
  }

  passwordControl(): AbstractControl {
    return this.f['password'];
  }

  isEmailValid(): boolean {
    let control: AbstractControl = this.emailControl();
    return !!(control.touched && control.errors);
  }

  isPasswordAndCredentialsValid(): boolean {
    let control: AbstractControl = this.passwordControl();
    return !!((control.touched && control.errors) || this.showLoginErrorMessage);
  }

  isLoginButtonDisabled(): boolean | null {
    return (this.passwordControl().errors || this.emailControl().errors) && this.loginForm.touched;
  }

  onInput(): void {
    this.showLoginErrorMessage = false;
  }

  onEmailSelected(email: string) {
    const selectedUser = this.cookieUsers.find(cookieUser =>
      cookieUser.email.toLowerCase() === email.toLowerCase()
    );
    if (selectedUser){
      this.loginForm.patchValue({ password: selectedUser.password })
    }
  }

  rememberMe() {
    this.remember = !this.remember
  }

  private handleLoginErrorResponse() {
    this.showLoginErrorMessage = true;
  }

  private handleLoginResponse(employee: AuthResponseModel) {
    this.service.saveToken(employee);
    employee.role == Role.EMPLOYEE ? this.router.navigateByUrl(`/profile/${employee.id}/personal`)
      : this.router.navigateByUrl('/home');
  }

  private filterEmails(value: string): LoginCredentialsModel[] {
    const filterValue = value.toLowerCase();
    return this.cookieUsers.filter(option => option.email.toLowerCase().includes(filterValue));
  }

  private saveInCookie(credentialsModel: LoginCredentialsModel) {
    if (this.remember){
      const user = this.cookieUsers.find(cookieUser =>
        cookieUser.email.toLowerCase() === credentialsModel.email.toLowerCase()
      );
      if (!user) {
        this.cookieUsers.push(credentialsModel)
        this.cookieService.set('currentUser', JSON.stringify(this.cookieUsers), 30);
      }
    }
  }

  private getCookieUsers() {
    const currentUserJson = this.cookieService.get('currentUser');
    if (currentUserJson) {
      this.cookieUsers =  JSON.parse(currentUserJson!);
    }
  }

  private searchByEmail() {
    this.filteredUsers = this.emailControl().valueChanges.pipe(
      startWith(''),
      map(value => this.filterEmails(value))
    );
  }
}
