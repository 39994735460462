<div class="modal-lg customize_popup">
  <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
    <div class="modal-content" *ngIf="!isView; else view" style="border-bottom: none;">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabelevent">
          {{ editPopup ? 'Edit event' : 'Add New Event' }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 p-0">
          <div>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label class="size-label">Request type</mat-label>
              <mat-select formControlName="timeOffCategory" (selectionChange)="onCategoryChange($event.value)">
              <mat-option [value]="TimeOffCategory.TIME_OFF">Time off</mat-option>
                <mat-option [value]="TimeOffCategory.SICK_LEAVE">Sick Leave</mat-option>
                <mat-option [value]="TimeOffCategory.VACATION">Vacation</mat-option>
                <mat-option [value]="TimeOffCategory.BUSINESS_TRIP">Business Trip</mat-option>
                <mat-option [value]="TimeOffCategory.WORKING_REMOTELY">Working Remotely</mat-option>
              </mat-select>
              <mat-error *ngIf="eventForm.controls['timeOffCategory'].hasError('required')">
                {{ ErrorMessages.FIELD_REQUIRED }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="row-without-padding p-0 col-12 mb-3"
               [style]="eventForm.controls['timeOffCategory'].touched && eventForm.controls['timeOffCategory'].hasError('required') ? 'margin-top: -5px;' : ''"
               style="margin-top: -18px; transition: all 0.3s;">
            <div class="justify-content-start w-50"
                 *ngIf="eventForm.controls ['timeOffCategory'].value == TimeOffCategory.TIME_OFF">
              <mat-checkbox formControlName="compensatory">
              <span *ngIf="eventForm.controls ['timeOffCategory'].value == TimeOffCategory.TIME_OFF" class="remember-me">Compensatory time off</span>
              </mat-checkbox>
            </div>

            <div class="checkbox-container justify-content-end w-50">
              <mat-checkbox formControlName="isAllDay"
                            [disabled]="eventForm.get('timeOffCategory')?.value === TimeOffCategory.VACATION ||
                                       eventForm.get('timeOffCategory')?.value === TimeOffCategory.SICK_LEAVE ||
                                       eventForm.get('timeOffCategory')?.value === TimeOffCategory.BUSINESS_TRIP">
                <span class="remember-me">All day</span>
              </mat-checkbox>
            </div>
          </div>

          <div class="row-without-padding p-0 col-12">
            <div *ngIf="eventForm.controls['isAllDay'].value" class="form-popup col-6 pl-0" style="padding-right: 3px;">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label >Start date</mat-label>
                <input readonly matInput [matDatetimepicker]="startPicker" aria-label="24hr format"
                       autocomplete="false" formControlName="startDateTime">
                <mat-datetimepicker-toggle matIconSuffix [for]="startPicker"
                                           *ngIf="!isView"></mat-datetimepicker-toggle>
                <mat-datetimepicker #startPicker type="date"></mat-datetimepicker>
                <mat-error *ngIf="editPopup && eventForm.controls['startDateTime'].hasError('required')">
                  {{ ErrorMessages.FIELD_REQUIRED }}
                </mat-error>

                <mat-error class="add-event-error-width"
                           *ngIf="eventForm.controls['endDateTime'].hasError('dateOverlap') || eventForm.controls['startDateTime'].hasError('dateOverlap')">
                  {{ ErrorMessages.DATE_OVERLAP }}
                </mat-error>

                <mat-error class="add-event-error-width"
                           *ngIf="eventForm.hasError('timeDifference')">
                  {{ ErrorMessages.PARTIAL_TIME_OFF_LENGTH }}
                </mat-error>
                <mat-error class="add-event-error-width"
                           *ngIf="eventForm.controls['startDateTime'].hasError('timeOffError')">
                  {{ ErrorMessages.START_DATE_PASTE }}
                </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="!eventForm.controls['isAllDay'].value" class="form-popup col-6 pl-0"
                 style="padding-right: 3px;">
              <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label class="label-mat">Start date & time</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker" formControlName="startDateTime" [required]="true" [readonly]="true">
                <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
                <ngx-mat-datetime-picker [showSeconds]="false" #picker></ngx-mat-datetime-picker>
                <mat-error
                  *ngIf="eventForm.controls['startDateTime'].hasError('required')">
                  {{ ErrorMessages.FIELD_REQUIRED }}
                </mat-error>

                <mat-error class="add-event-error-width"
                           *ngIf="eventForm.controls['endDateTime'].hasError('dateOverlap') || eventForm.controls['startDateTime'].hasError('dateOverlap')">
                  {{ ErrorMessages.DATE_OVERLAP }}
                </mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="!eventForm.controls['isAllDay'].value" class="form-popup col-6 pr-0" style="padding-left: 3px;">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label  class="label-mat">End date & time</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker" formControlName="endDateTime"
                       [required]="true" [readonly]="true">
                <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                <mat-error *ngIf="eventForm.controls['endDateTime'].hasError('required')">
                  {{ ErrorMessages.FIELD_REQUIRED }}
                </mat-error>
                <mat-error *ngIf="eventForm.controls['endDateTime'].hasError('timeError')">
                  {{ ErrorMessages.TIME_DURATION_ERROR }}
                </mat-error>
                <mat-error *ngIf="eventForm.controls['endDateTime'].hasError('timeDifference')">
                  {{ ErrorMessages.PARTIAL_TIME_OFF_LENGTH }}
                </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="eventForm.controls['isAllDay'].value" class="form-popup col-6 pr-0" style="padding-left: 3px;">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>End date</mat-label>
                <input readonly matInput [matDatetimepicker]="endPicker" autocomplete="false"
                       formControlName="endDateTime">
                <mat-datetimepicker-toggle matIconSuffix [for]="endPicker"></mat-datetimepicker-toggle>
                <mat-datetimepicker #endPicker type="date"></mat-datetimepicker>
                <mat-error *ngIf="eventForm.controls['endDateTime'].hasError('required')">
                  {{ ErrorMessages.FIELD_REQUIRED }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="form-popup"
               [style]="getReasonStyles()">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label class="size-label">Reason</mat-label>
              <textarea matInput formControlName="reason"
                        autocomplete="off"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5"></textarea>
              <mat-error *ngIf="eventForm.get('reason')?.hasError('required')">Reason is required</mat-error>
              <mat-error *ngIf="eventForm.get('reason')?.hasError('maxlength')">Reason cannot exceed 900 characters</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="eventForm.hasError('nonValid')" class="form-popup">
             <span class="text-danger mb-1">
                You can not save event.
            </span>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button mat-dialog-close
                (click)="closeDialog()" type="button"
                class="effect-btn btn-effect-custom btn-danger-custom squer-btn"
                data-dismiss="modal">Cancel
        </button>
        <button type="submit" [disabled]="isDisabled"
                class="effect-btn btn-effect-custom btn-success-custom squer-btn">{{ editPopup ? 'Save' : 'Add' }}
        </button>
      </div>

    </div>
  </form>

  <ng-template #view>
    <div class="modal-body">
      <div class="d-flex pt-1">
        <div class="pl-1" *ngIf="event.requestStatus === 'PENDING'">
          <a class="p-2 pl-3 pr-3 pending-style">{{ event.requestStatus }}</a>
        </div>
        <div>
          <button class="btn close-button" type="button" (click)="closeDialog()">
            <span aria-hidden="true" class="">&times;</span>
          </button>
        </div>
      </div>
      <div class="container pt-4 tr-3 pb-4 pl-3">
        <div class="row" style="justify-content: space-between">
          <div>
            <h1 class="mb-0">{{ eventForm.controls['timeOffCategory'].value | keyValue: timeOffCategoryMap }}</h1>
          </div>

          <div>
            <button *ngIf="((currentUserId == data.timeOff.employee.id && (event.requestStatus == 'PENDING' || currentUserRole === Role.ADMIN)))" type="button"
                    class="btn" (click)="edit()"><i class="fa-solid fa-pencil edit-button-icon"   ></i>
            </button>

            <button *ngIf="((currentUserId == data.timeOff.employee.id && event.requestStatus == 'PENDING') || currentUserRole === Role.ADMIN )" class="btn" type="button"
                    (click)="deleteTimeOff(data.timeOff.requestId)"><i class="fa-sharp fa-regular fa-trash-can fa-xs"></i>
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <h4 class="col-12 mb-0" *ngIf="eventForm.controls['compensatory'].value">Compensatory time off</h4>
        </div>
        <div class="row mb-3">
          <div class="cursor-pointer" (click)="navigateToProfile(); closeDialog()">
            <div class="profile-container">
              <div class="table-img">
                <a>
                  <img [src]="data.timeOff.employee | userImage | async" alt="Profile Picture" class="img-table"/>
                </a>
              </div>
              <div class="full-name flex-grow-3">{{ data.timeOff.employee.fullName }}</div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <p class="col-12" *ngIf="eventForm.controls ['isAllDay'].value"><span class="from-to">From</span>
            <span
              class="material-symbols-outlined align-middle calendar-icon">calendar_month</span> {{  eventForm.controls["startDateTime"].value  | date:"fullDate" }}
          </p>
          <p class="col-12" *ngIf="!eventForm.controls ['isAllDay'].value"><span class="from-to">From</span>
            <span
              class="material-symbols-outlined align-middle calendar-icon">calendar_month</span> {{  eventForm.controls["startDateTime"].value | date:"MMMM d, y | HH:mm" }}
          </p>
        </div>

        <div class="row mb-3">
          <p class="col-12" *ngIf="eventForm.controls ['isAllDay'].value"><span class="from-to">To</span>
            <span
              class="material-symbols-outlined align-middle calendar-icon">calendar_month</span> {{ eventForm.controls["endDateTime"].value | date:"fullDate" }}
          </p>
          <p class="col-12" *ngIf="!eventForm.controls ['isAllDay'].value"><span class="from-to">To</span>
            <span
              class="material-symbols-outlined align-middle calendar-icon">calendar_month</span> {{ eventForm.controls["endDateTime"].value | date:"MMMM d, y | HH:mm" }}
          </p>
        </div>
        <div *ngIf="data.timeOff.reason" class="row mb-3" style="max-width: 400px; max-height: 200px; overflow: hidden; white-space: normal; word-wrap: break-word;">
          <p class="col-12" *ngIf="eventForm.controls['reason'].value">{{ eventForm.controls["reason"].value }}</p>
        </div>
      </div>
    </div>

  </ng-template>
</div>
