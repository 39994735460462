import {BadgeResponseModel} from "./badge-response.model";
import {EmployeeInfoResponseModel} from "../employee/employee-info-response.model";

export class EmployeeBadgeResponseModel {
  sender: EmployeeInfoResponseModel;
  receiver: EmployeeInfoResponseModel;
  badge: BadgeResponseModel;
  comment: string;
  createdAt: Date;
}
