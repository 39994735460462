<div class="d-flex">
  <div class="wrapper" #wrapper
       (mousedown)="onMouseDown($event)"
       (mouseleave)="onMouseLeave()"
       (mouseup)="onMouseUp()"
       (mousemove)="onMouseMove($event)"
       (touchstart)="onTouchStart($event)"
       (touchmove)="onTouchMove($event)"
       (touchend)="onTouchEnd($event)">

    <button [ngClass]="selectedCategoryId === 0 ? 'category-active-btn' : ''"
            class="btn category-btn p-2 item"
            (click)="selectCategory(0)">All
    </button>

    <div *ngFor="let category of categories">
      <button [ngClass]="selectedCategoryId === category.id ? 'category-active-btn' : 'category-btn'"
              class="btn category-btn p-2 item"
              (click)="selectCategory(category.id)">
        {{ category.name }}
      </button>
    </div>
  </div>
  <button *ngIf="isScrollable && categories.length > visibleItemsCount"
          (click)="scrollRight()" class="btn next-btn">
    <i class="fa-solid fa-angle-right next-btn"></i>
  </button>
</div>
