<ul class="d-flex justify-content-start flex-wrap p-3">
  <li class="d-flex align-items-center flex-column mr-1" *ngFor="let employeeBadge of employeeBadgeList">
    <div class="badge-container position-relative">
      <img class="badge-img"
           [src]="employeeBadge.badge.id | badgeImage | async"
           [alt]="employeeBadge.badge.name"
           (click)="sendBadge(employeeBadge)">
    </div>
    <h5 class="text-center" *ngIf="isReceivedBadge; else sentBadge">
      by {{ employeeBadge.sender.fullName }}
    </h5>
    <ng-template #sentBadge>
      <h5 class="text-center">to {{ employeeBadge.receiver.fullName }}</h5>
    </ng-template>
  </li>
</ul>
