import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";
import {from} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  private readonly DEFAULT_FORMAT = 'dd-MM-yyyy';
  private readonly DATE_TIME_FORMAT = 'dd MMMM yyyy | HH:mm';
  private readonly DATE_FORMAT = 'dd MMMM yyyy';

  constructor(private datePipe: DatePipe) {
  }

  public getFormattedDate(date: Date, format?: string) {
    const formatString = format ? format : this.DEFAULT_FORMAT;
    const formatDate = this.datePipe.transform(date, formatString);
    if (typeof formatDate === 'string') {
      return formatDate;
    }else {
      return "";
    }
  }

  transformDate(startDate: Date, endDate: Date): string | null {
    let verifiedStartDate = new Date(startDate);
    let verifiedEndDate = new Date(endDate);

    const transformStart = this.getFormattedDate(verifiedStartDate, this.DATE_TIME_FORMAT);
    const transformEnd = this.getFormattedDate(verifiedEndDate, this.DATE_TIME_FORMAT);

    if (!transformStart || !transformEnd) {
      return null;
    }

    if (this.isOneDay(startDate, endDate)) {
      const transformStartWithoutTime = this.getFormattedDate(verifiedStartDate, this.DATE_FORMAT);
      return `${transformStartWithoutTime}`;
    }

    return `${transformStart} - ${transformEnd}`;
  }

  private isOneDay(first: Date, second: Date): boolean {
    let firstDateVerified = new Date(first);
    let secondDateVerified = new Date(second);

    return new Date(firstDateVerified).getTime() === new Date(secondDateVerified).getTime();
  }

}
