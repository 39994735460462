import {Component, Input} from '@angular/core';
import {EmployeeBadgeResponseModel} from "../../../core/model/badge/employee-badge-response.model";

@Component({
  selector: 'app-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.css']
})
export class BadgeListComponent {

  @Input() employeeBadgeList: Array<EmployeeBadgeResponseModel> = []

  @Input() isReceivedBadge = false;
}
