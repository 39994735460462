import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthService} from "../service/auth.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private headers = new HttpHeaders()
    .set('Authorization', 'Bearer ' + this.auth.getToken());
  private params = new HttpParams()

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  get<T>(url: string, param?: HttpParams): Observable<T> {
    this.updateOptionsBeforeRequest(param);
    return this.http.get(url, this.getOptions,) as Observable<T>;
  }

  getBlob<T>(url: string): Observable<T> {
    this.updateOptionsBeforeRequest();
    return this.http.get(url, {responseType: 'blob', ...this.getOptions}) as Observable<T>;
  }

  post<T>(url: string, body: any): Observable<T> {
    this.updateOptionsBeforeRequest();
    return this.http.post(url, body, this.getOptions) as Observable<T>;
  }

  put<T>(url: string, body: any): Observable<T> {
    this.updateOptionsBeforeRequest();
    return this.http.put(url, body, this.getOptions) as Observable<T>;
  }

  delete<T>(url: string): Observable<T> {
    this.updateOptionsBeforeRequest();
    return this.http.delete(url, this.getOptions) as Observable<T>;
  }

  get getOptions() {
    return {headers: this.headers, params: this.params};
  }

  private updateOptionsBeforeRequest(param?: HttpParams): void {
    this.headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.auth.getToken());
    if (param) {
      this.params = param;
    } else {
      this.params = new HttpParams();
    }
  }
}
