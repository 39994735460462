/*
  this class is for CSS related methods and variables, the class can always be modified
*/
export class Styles {
  public static generateTransition(numberOfSeconds : number, important?: boolean): string {
    if (!numberOfSeconds) {
      return '';
    }

    return important ? `transition: ${numberOfSeconds}s !important;` : `transition: ${numberOfSeconds}s;`;
  }

  public static generateMarginTop(numberOfPixels : number, important?: boolean): string {
    if (!numberOfPixels) {
      return '';
    }

    return important ? `margin-top: ${numberOfPixels}px !important;` : `margin-top: ${numberOfPixels}px;`;
  }

  public static generateColor(color: string, important?: boolean): string {
    if (!color) {
      return '';
    }

    return important ? `color: ${color} !important;` : `color: ${color};`;
  }

  public static generateBorderColor(color: string, important?: boolean): string {
    if(!color) {
      return '';
    }

    return important ? `border-color: ${color} !important;` : `border-color: ${color};`;
  }

  public static generateWidth(value: string): string {
    if(!value) {
      return '';
    }

    return `width: ${value};`;
  }

  public static generateTextAlign(value: string): string {
    if(!value) {
      return '';
    }

    return `text-align: ${value};`;
  }

  public static readonly DEPARTMENT_TITLE_COLORS = [
    '#924AEF', '#E328AF', '#5ECFFF',
    '#FF9325', '#38E25D', '#FFCE37',
    '#4E6EFF', '#FF4A4A', '#3CFFC0',
    '#FF66C4', '#A95EFF', '#FFC74A',
    '#4AFFE3', '#FF855E', '#6EFF75'
  ];

  public static readonly DEPARTMENT_TITLE_HOVER_COLORS = [
    '#B67EF6', '#ED69C7', '#78D2FF',
    '#FFAB66', '#5EEA87', '#FFDE8A',
    '#8AAFFF', '#FF8181', '#77FFC5',
    '#FF94DA', '#CC99FF', '#FFDB8A',
    '#94FFE7', '#FFA48A', '#8AFFA3'
  ];
}
