import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../core/service/auth.service";
import {AuthResponseModel} from "../../core/model/auth/auth-response.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Role} from "../../core/model/employee/role.enum";
import {LoginCredentialsModel} from "../../core/model/auth/login-credentials.model";
import {Styles} from "../../core/constant/styles.constant";
import {CookieService} from "ngx-cookie-service";
import {map, Observable, startWith} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide = true;
  remember: boolean = false;
  cookieUsers: LoginCredentialsModel[] = [];
  filteredUsers: Observable<LoginCredentialsModel[]>;

  public readonly TRANSITION_05: string = Styles.generateTransition(0.5);
  public readonly MARGIN_TOP_30: string = Styles.generateMarginTop(30);

  protected showLoginErrorMessage: boolean = false;
  private returnUrl: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
  }

  ngOnInit(): void {
    this.handleAutoLogin();
    this.getCookieUsers();
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.searchByEmail();
  }

  private handleAutoLogin(): void {
    this.authService.autoLogin().subscribe((isTokenValid) => {
      if (isTokenValid) {
        sessionStorage.setItem("currentUser", JSON.stringify(this.authService.getCurrentUser()));
        sessionStorage.setItem("token", this.authService.getCurrentUser().token);
        const rolePath = this.authService.getCurrentUserRole() === Role.EMPLOYEE
          ? `/profile/${this.authService.getCurrentUser().id}/personal`
          : '/home';
        this.router.navigateByUrl(rolePath).catch();
      } else {
        this.authService.logout();
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    if (this.isLoginButtonDisabled()) {
      return;
    }

    const credentialsModel: LoginCredentialsModel = this.loginForm.getRawValue();
    this.authService.auth(credentialsModel, this.remember).subscribe({
      next: (employee: AuthResponseModel) => {
        this.handleLoginResponse(employee);
        this.saveInCookie(credentialsModel)
      },
      error: () => {
        this.handleLoginErrorResponse();
      }
    });
  }

  emailControl(): AbstractControl {
    return this.f['email'];
  }

  passwordControl(): AbstractControl {
    return this.f['password'];
  }

  isEmailValid(): boolean {
    return this.isControlInvalid(this.emailControl());
  }

  isPasswordAndCredentialsValid(): boolean {
    return this.isControlInvalid(this.passwordControl()) || this.showLoginErrorMessage;
  }

  isLoginButtonDisabled(): boolean {
    return this.loginForm.invalid && this.loginForm.touched;
  }

  onInput(): void {
    this.showLoginErrorMessage = false;
  }

  onEmailSelected(email: string): void {
    const selectedUser = this.cookieUsers.find(user =>
      user.email.toLowerCase() === email.toLowerCase()
    );
    if (selectedUser) {
      this.loginForm.patchValue({ password: selectedUser.password });
    }
  }

  rememberMe(): void {
    this.remember = !this.remember;
  }

  private handleLoginErrorResponse(): void {
    this.showLoginErrorMessage = true;
  }

  private handleLoginResponse(employee: AuthResponseModel): void {
    this.authService.saveToken(employee, this.remember);
    let rolePath;
    if (this.returnUrl === '') {
      rolePath = employee.role === Role.EMPLOYEE
        ? `/profile/${employee.id}/personal`
        : '/home';
    } else {
      rolePath = this.returnUrl
    }
    this.router.navigateByUrl(rolePath);
  }

  private filterEmails(value: string): LoginCredentialsModel[] {
    const filterValue = value.toLowerCase();
    return this.cookieUsers.filter(user =>
      user.email.toLowerCase().includes(filterValue)
    );
  }

  private getCookieUsers(): void {
    const currentUserJson = this.cookieService.get('currentUser');
    if (currentUserJson) {
      this.cookieUsers = JSON.parse(currentUserJson);
    }
  }

  private searchByEmail(): void {
    this.filteredUsers = this.emailControl().valueChanges.pipe(
      startWith(''),
      map(value => this.filterEmails(value || ''))
    );
  }

  private isControlInvalid(control: AbstractControl): boolean {
    return control.touched && !!control.errors;
  }

  private saveInCookie(credentialsModel: LoginCredentialsModel) {
    if (this.remember) {
      const user = this.cookieUsers.find(cookieUser =>
        cookieUser.email.toLowerCase() === credentialsModel.email.toLowerCase()
      );
      if (!user) {
        this.cookieUsers.push(credentialsModel)
        this.cookieService.set('currentUser', JSON.stringify(this.cookieUsers), 30);
      }
    }
  }
}
