<div [ngClass]="{'diagram-hidden': !isDiscordUsernamePresent}" class="chart-container row">
  <div class="datepicker col-xl-12 col-sm-12 col-12 d-flex justify-content-between align-items-center">

    <ul class="p-0">
      <li class="legend" [ngClass]="{'diagram-hidden': !showDiagram}">
      <span class="legend-item" *ngFor="let legendItem of lineChartData.datasets; let i = index"
            (click)="hideLegend(i)">
        <span class="legend-rect"
              [style.background-color]="legendItem.backgroundColor"></span>
        <span [ngClass]="legendItem.hidden ? 'hidden' : 'show'"
              [style.color]="legendItem.backgroundColor">{{ legendItem.label }}</span>
      </span>
      </li>
    </ul>

    <app-date-range-calendar (dateRangeSelected)="onDateRangeSelected($event)"></app-date-range-calendar>

  </div>
  <div [ngClass]="{'diagram-hidden': !showDiagram}"  class="chart col-xl-12 col-sm-12 col-12">
    <canvas class="canvas" baseChart [height]="CHART_HEIGHT" [width]="CHART_WIDTH"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [type]="lineChartType">
    </canvas>
  </div>
  <span [ngClass]="{'diagram-hidden': showDiagram}" class="error-text pl-3">No statistics available</span>
</div>
<span [ngClass]="{'diagram-hidden': isDiscordUsernamePresent}" class="error-text">No statistics available</span>
