import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from ".././service/auth.service";

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router)
  if(!authService.getCurrentUser() || !authService.getToken()) {
    router.navigateByUrl("login").then();
  }
  return authService.isLoggedIn();
};
