import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCategoryPipe'
})
export class FormatCategoryPipe implements PipeTransform {

  transform(value: any): string {
    const category = value ? value.toString().replaceAll('_', ' ').toLowerCase() : '';

    return category
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

}
