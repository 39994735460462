import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-date-range-calendar',
  templateUrl: './date-range-calendar.component.html',
  styleUrls: ['./date-range-calendar.component.css'],
})
export class DateRangeCalendarComponent implements OnInit {

  @Output() dateRangeSelected = new EventEmitter<{ start: Date; end: Date }>();
  range: FormGroup;
  protected today: Date;
  protected twentyDayBefore: Date;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.createForm();
    this.handleStartDateChanges();
  }

  onCancel() {
    this.range.reset({
      start: this.twentyDayBefore,
      end: this.today,
    });
    this.emitDateRangeSelected()
  }

  onApply() {
    if (this.range.valid) {
      this.emitDateRangeSelected()
    }
  }

  private emitDateRangeSelected() {
    const selectedRange = {
      start: this.range.value.start,
      end: this.range.value.end,
    };
    this.dateRangeSelected.emit(selectedRange);
  }

  private createForm() {
    this.today = new Date();
    this.twentyDayBefore = new Date();
    this.twentyDayBefore.setDate(this.today.getDate() - 30);
    this.range = this.fb.group({
      start: [this.today, Validators.required],
      end: [{value: this.twentyDayBefore, disabled: false}, Validators.required]
    });
  }

  private handleStartDateChanges() {
    this.range.controls['start'].valueChanges.subscribe(startDate => {
      if (startDate) {
        this.range.controls['end'].enable();
        this.applyEndDateValidators(startDate);
      } else {
        this.range.controls['end'].disable();
      }
    });
  }

  private applyEndDateValidators(startDate: Date) {
    this.range.controls['end'].setValidators([Validators.required, this.endDateValidator(startDate)]);
    this.range.controls['end'].updateValueAndValidity();
  }

  private endDateValidator(startDate: Date): ValidatorFn {
    return (control: AbstractControl) => {
      const endDate = control.value as Date;
      return endDate && endDate.getTime() === startDate.getTime() ? {sameDate: true} : null;
    };
  }
}
