import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from "@angular/router";
import {authGuard} from "./core/guard/auth.guard";

const routes: Routes = [
  {path: 'login', loadChildren: () => import("./views/login/login.module").then(m => m.LoginModule)},

  {
    path: '',
    loadChildren: () => import("./views/main/main.module").then(m => m.MainModule),
    canActivate: [authGuard],
  },
  {path: '**', redirectTo: '/home'}
];
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top'
};
@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
