<div class="modal-lg ">

  <div class="modal-content" style="border: none">
    <div class="modal-header">
      <div class="container d-flex align-items-center">
        <h3 class="modal-title mb-0">{{badge.categoryName}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>

    <div class="modal-body">
      <div class=" col-md-12 p-0">
        <div class="container mb-3">
          <div class="d-flex justify-content-md-start">
            <img class="img-fluid badge-img badge-give-img"
                 [src]="badge.id | badgeImage | async" [alt]="badge.name">

            <div class="d-flex flex-column justify-content-center">
              <div class="text-color"><h3 class="mb-1 text-break">{{badge.name}}</h3></div>
              <div class="text-color" ><p class="text-break"> {{badge.description}}</p></div>
            </div>
          </div>
          <div class="photo-container pt-2 pl-4">
            <div *ngFor="let receiver of (showAllReceivers ? receivers : receivers.slice(0, 7))" class="photo">
              <img class="profile-image radius_photo" [src]="receiver | userImage | async">
              <span class="tooltip">{{ receiver.firstname + ' ' + receiver.lastname }}</span></div>
            <button *ngIf="receivers.length > 7"
                    class="view-btn1 custom-menu-button mat-menu-updating-z-index mt-2"
                    mat-icon-button [matMenuTriggerFor]="menu"
                    #menuTrigger="matMenuTrigger">
    <span class="custom-menu-button distance-word">
      {{ menuTrigger.menuOpen ? '' : 'View more' }}
    </span>
            </button>
          </div>
          <mat-menu #menu="matMenu">
            <div class="in-view-popup" *ngFor="let receiver of receivers.slice(7)">
              <img class="badge-img-sender-menu" [src]="receiver ? (receiver | userImage | async) : ''">
              <span class="view-more-text">
      {{ receiver.firstname + ' ' + receiver.lastname }}
                <hr>
    </span>
            </div>
          </mat-menu>
        </div>
          <div class="container pt-2">
          <div class="d-flex flex-column">
            <form class="w-100" [formGroup]="sendBadgeForm" (ngSubmit)="onSubmit()">
              <mat-form-field class="w-100 mb-1" appearance="outline">
                <mat-label class="label">Add Person</mat-label>
                <mat-select formControlName="employee">
                  <mat-option>
                    <ngx-mat-select-search
                      formControlName="employeeFilter"
                      [searching]="searching"
                      [placeholderLabel]="search"
                      [noEntriesFoundLabel]="'Not found'"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                    {{ employee.fullName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="sendBadgeForm.controls['employee'].hasError('nonValidEmployee')">
                  Select valid employee
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-100" appearance="outline">
                <mat-label class="label">Your colleague did something great? Tell about it!</mat-label>
                <textarea class="comment-area" matInput formControlName="comment" appNoWhitespace
                ></textarea>
                <mat-error *ngIf="sendBadgeForm.controls['comment'].hasError('required')">
                  Comment is required.
                </mat-error>
                <mat-error *ngIf="sendBadgeForm.controls['comment'].hasError('minlength')">
                  Description can not be less then 50 characters
                </mat-error>
                <mat-error *ngIf="sendBadgeForm.controls['comment'].hasError('maxlength')">
                  Description can not be more then 200 characters
                </mat-error>
              </mat-form-field>
              <span class="text-danger mb-1" *ngIf="sendBadgeForm.controls['employee'].hasError('nonValid')">
                You can not send badge.
              </span>
              <div class="form-group pt-3">
                <button type="submit" [disabled]="isSubmitted || sendBadgeForm.invalid" class="btn register-btn">Give now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
