<div class="modal-lg ">

  <div class="modal-content" style="border: none">
    <div class="modal-header">
      <div class="container d-flex align-items-center">
        <h3 class="modal-title mb-0">{{badge.categoryName}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>

    <div class="modal-body">
      <div class=" col-md-12 p-0">
        <div class="container mb-3">
          <div class="d-flex justify-content-md-start">
            <img class="img-fluid badge-img badge-give-img"
                 [src]="badge.id | badgeImage | async" [alt]="badge.name">

            <div class="d-flex flex-column justify-content-center">
              <div class="text-color"><h3 class="mb-1">{{badge.name}}</h3></div>
              <div class="text-color"><p> {{badge.description}}</p></div>
            </div>
          </div>

        </div>
        <div class="container">
          <div class="d-flex flex-column">
            <form class="w-100" [formGroup]="sendBadgeForm" (ngSubmit)="onSubmit()">
              <mat-form-field class="w-100 mb-1" appearance="outline">
                <mat-label class="label">Add Person</mat-label>
                <mat-select formControlName="employee" placeholder="Employee">
                  <mat-option>
                    <ngx-mat-select-search formControlName="employeeFilter"
                                           [searching]="searching"
                                           placeholderLabel= 'Search'
                                           [noEntriesFoundLabel]="'Not found'"
                    ></ngx-mat-select-search>
                  </mat-option>

                  <mat-option *ngFor="let employee of filteredEmployees | async" [value]="employee">
                    {{employee.fullName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="sendBadgeForm.controls['employee'].hasError('nonValidEmployee')">
                  Select valid employee
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-100" appearance="outline">
                <mat-label class="label">Your colleague did something great? Tell about it!</mat-label>
                <textarea class="comment-area" matInput formControlName="comment"
                ></textarea>
              </mat-form-field>
              <span class="text-danger mb-1" *ngIf="sendBadgeForm.controls['employee'].hasError('nonValid')">
                You can not send badge.
              </span>
              <div class="form-group">
                <button type="submit" [disabled]="isSubmitted" class="btn register-btn">Give now</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
