<div class="modal-lg customize_popup">
  <div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabela">{{ data.message }}</h5>
      </div>

      <div class="modal-footer">
        <a class="effect-btn btn-effect-custom btn-danger-custom squer-btn"
           type="button" [mat-dialog-close]="true">Delete
        </a>
        <a class="effect-btn btn-effect-custom btn-success-custom squer-btn"
           type="button" mat-dialog-close>Cancel
        </a>
      </div>

    </div>
  </div>
</div>
