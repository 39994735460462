import {Injectable} from '@angular/core';
import {BadgeCategoryModel} from "../model/badge/badge-category.model";
import {HttpService} from "../util/http.service";
import {environment} from "../../../environments/environment";
import {BadgeResponseModel} from "../model/badge/badge-response.model";
import {Observable} from "rxjs";
import {EmployeeBadgeRequestModel} from "../model/badge/employee-badge-request.model";
import {EmployeeBadgeResponseModel} from "../model/badge/employee-badge-response.model";
import {HttpParams} from "@angular/common/http";
import {PageableModel} from "../model/pagination/pageable.model";
import {BadgePageModel} from "../model/badge/badge-page-model";
import {EmployeeBadgePageModel} from "../model/badge/employee-badge-page.model";
import {BadgeCategoryStatusEnum} from "../model/badge/badge-category-status.enum";

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  private BASE_PATH: string = environment.apiURL;
  private VERSION: string = environment.version;
  private BADGE_PATH: string = environment.badgePath;
  private CATEGORY: string = environment.getAllBadgeCategories;
  private ALL_BADGE: string = environment.getAllBadge;
  private ALL_RECEIVED: string = environment.allReceived;
  private ALL_SENT: string = environment.allSent;
  private BY_CATEGORY: string = environment.byCategory;
  private FILE_CONTROLLER: string = environment.files;
  private SEND_BADGE: string = environment.send;
  private UPDATE_BADGE: string = environment.update;

  constructor(private http: HttpService) {
  }

  public getAllBadgeCategories(status: BadgeCategoryStatusEnum, id?: number,): Observable<Array<BadgeCategoryModel>> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}${this.CATEGORY}?status=${status}`;
    if (id) {
      path += `&id=${id}`;
    }
    return this.http.get<Array<BadgeCategoryModel>>(path);
  }

  public getAllByCategory(categoryId: number, pageable: PageableModel): Observable<BadgePageModel> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}${this.ALL_BADGE}`;
    if (categoryId != 0) {
      path += `${this.BY_CATEGORY}${categoryId}`
    }
    let param = new HttpParams().set('page', pageable.page).set('size', pageable.size);
    return this.http.get<BadgePageModel>(path, param);
  }

  public getImage(badgeId: number): Observable<Blob> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.FILE_CONTROLLER}${this.BADGE_PATH}/${badgeId}`;
    return this.http.getBlob<Blob>(path);
  }

  public sendBadge(employeeBudge: EmployeeBadgeRequestModel): Observable<EmployeeBadgeResponseModel> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}${this.SEND_BADGE}`;
    return this.http.post(path, employeeBudge);
  }

  public getAllReceivedEmployeeBadgeByEmployeeIdAndCategoryId(employeeId: number, categoryId: number, pageable: PageableModel): Observable<EmployeeBadgePageModel> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}${this.ALL_RECEIVED}/${employeeId}`;
    if (categoryId != 0) {
      path += `${this.BY_CATEGORY}${categoryId}`
    }
    let param = new HttpParams()
      .set('page', pageable.page)
      .set('size', pageable.size);
    return this.http.get<EmployeeBadgePageModel>(path, param);
  }

  public getAllSentEmployeeBadgeByEmployeeIdAndCategoryId(employeeId: number, categoryId: number, pageable: PageableModel): Observable<EmployeeBadgePageModel> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}${this.ALL_SENT}/${employeeId}`;
    if (categoryId != 0) {
      path += `${this.BY_CATEGORY}${categoryId}`
    }
    let param = new HttpParams()
      .set('page', pageable.page)
      .set('size', pageable.size);
    return this.http.get<EmployeeBadgePageModel>(path, param);
  }

  public createBadge(formData: FormData): Observable<BadgeResponseModel> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}`;
    return this.http.post<BadgeResponseModel>(path, formData);
  }

  public archivedBadge(id: number): Observable<void> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}/archived/${id}`;
    return this.http.get<void>(path);
  }

  public update(id: number, formData: FormData): Observable<BadgeResponseModel> {
    let path: string = `${this.BASE_PATH}${this.VERSION}${this.BADGE_PATH}${this.UPDATE_BADGE}/${id}`;
    return this.http.put<BadgeResponseModel>(path, formData);
  }
}

