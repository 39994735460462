<div class="main-wrapper">
  <div class="page-wrapper">
    <div class="content container-fluid">
      <div class="row">
        <div class="add-badges col-xl-12 col-sm-12 col-12 mb-4">
          <div class="breadcrumb-path mb-4">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <app-breadcrumb-home></app-breadcrumb-home>
                <span class="breadcrumb-slash">/</span>
                <a>Give Badge</a>
              </li>
            </ul>
            <span class="text-center last-item justify-content-end">Give Badge</span>
          </div>
        </div>

        <div class="add-badges col-xl-12 col-sm-12 mb-4" *ngIf="currentUserRole === Role.ADMIN">
          <div class="d-flex justify-content-end mb-2 mr-1">
            <div class="small-screen-badge">
              <a class="btn-add add-badge" (click)="addBadges()"><i data-feather="plus" class="add-badge"></i> Add
                Badges</a>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-sm-12 mb-4" *ngIf="badges.length > 0,else notBadges">
          <div>
            <div class="breadcrumb-path category-list">

              <app-category-scroll class="w-100" [categories]="badgeCategories"
                                   (selectedCategory)="selectCategory($event)"></app-category-scroll>
            </div>
          </div>

          <div class="container-fluid mt-2 p-0" *ngIf="badges != null && badges.length > 0">
            <ul class="d-flex flex-wrap p-0 space">
              <li class="mr-3 mb-3" *ngFor="let badge of badges">
                <div class="d-flex flex-column align-items-center position-relative">
                  <img class="img-fluid badge-img"
                       [src]="badge.id | badgeImage | async" [alt]="badge.name"
                       [ngClass]="{'locked': !badge.isAllowed}"
                       (click)="openSendBadgeDialog(badge)">
                  <div *ngIf="!badge.isAllowed" class="lock-overlay">
                    <i class="fa-solid fa-lock lock-icon"></i>
                  </div>
                  <div *ngIf="currentUserRole == Role.ADMIN">
                    <div class="row mt-2">
                      <div (click)="editBadge(badge)">
                        <i class="fa-solid fa-pencil edit"></i>
                      </div>
                      <div (click)="archivedBadge(badge.id)">
                        <i class="fa-sharp fa-regular fa-trash-can fa-xs trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div *ngIf="badges != null && badges.length == 0" class="h2-div">
            <h2 class="h2-text">No Badges available</h2>
          </div>

          <div class="col-xl-12 col-sm-12 mb-4 d-flex justify-content-end bottom-0 end-0 mb-2">
            <button class="btn add-badge see-more-btn me-2"
                    *ngIf="moreButton && badges.length >= 21 && totalElements != 21"
                    (click)="moreBadges()">See More
            </button>
            <button class="btn add-badge see-more-btn"
                    *ngIf="lessButton"
                    (click)="lessBadges()">See Less
            </button>
          </div>
        </div>
        <ng-template #notBadges>
          <div class="col-xl-12 col-sm-12 col-12 ">
            <div class="breadcrumb-path mb-4">
              <span class="error-msg">There are no badges</span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
