import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {OverlayContainer} from "@angular/cdk/overlay";

@Directive({
  selector: '[appMenuZIndex]'
})
export class MenuZIndexDirective implements AfterViewInit {

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private overlayContainer: OverlayContainer
  ) {
  }

  ngAfterViewInit() {
    const menuTriggers: HTMLCollectionOf<Element> = this.el.nativeElement.getElementsByClassName('mat-menu-updating-z-index');
    const menuTriggerArray = Array.from(menuTriggers);

    if (menuTriggerArray.length > 0) {
      menuTriggerArray.forEach(menuTriggerElement => {
        this.renderer.listen(menuTriggerElement, 'click', () => {
          setTimeout(() => {
            this.overlayContainer.getContainerElement().style.zIndex = '98';
          }, 0);
        });
      });
    }
  }

  @HostListener('window:locationchange')
  onWindowChange() {
    this.overlayContainer.getContainerElement().style.zIndex = '1000';
  }

  @HostListener('window:click')
  onDocumentClick() {
    this.overlayContainer.getContainerElement().style.zIndex = '1000';
  }
}
