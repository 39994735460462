<div class="main-wrapper login-body">
  <div class="login-wrapper">
    <div class="container">
      <div class="loginbox">
        <div class="login-left">
          <div class="login-left-wrap">
            <img class="img-fluid logo-dark" src="../../../assets/img/ITSpace.png" alt="Logo">
          </div>
        </div>
        <div class="login-top">
          <div class="login-top-wrap">
            <img class="img-fluid logo-dark" src="../../../assets/img/ITSpace.png" alt="Logo">
          </div>
        </div>
        <div class="login-right mr-2">
          <div class="login-right-wrap">
            <h1>Welcome to HR Space</h1>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

              <div class="form-group login-email-field">
                <mat-form-field class="mat-form-input" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input type="text" matInput formControlName="email" [matAutocomplete]="auto" placeholder="Enter or select email" >
                  <mat-autocomplete #auto="matAutocomplete" >
                    <mat-option *ngFor="let currentUser of filteredUsers | async" [value]="currentUser.email" (click)="onEmailSelected(currentUser.email)">
                      {{currentUser.email}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint *ngIf="emailControl().touched && emailControl().errors">
                    <p *ngIf="emailControl().errors?.['required']">
                      Email is required
                    </p>
                  </mat-hint>
                </mat-form-field>

                <div class="form-group" [style]="isEmailValid() ? MARGIN_TOP_30 + TRANSITION_05 : TRANSITION_05">
                  <div class="pass-group">
                    <mat-form-field class="mat-form-field" appearance="outline">
                      <mat-label>Password</mat-label>
                      <input
                        matInput
                        formControlName="password"
                        [type]="hide ? 'password' : 'text'"
                        (input)="onInput()">
                      <mat-icon
                        class="cursor-pointer"
                        matSuffix
                        (click)="hide = !hide">
                        {{hide ? 'visibility_off' : 'visibility'}}
                      </mat-icon>

                      <mat-hint *ngIf="passwordControl().touched && passwordControl().errors">
                        <p *ngIf="passwordControl().errors?.['required']">
                          Password is required
                        </p>
                      </mat-hint>
                      <mat-hint *ngIf="showLoginErrorMessage">
                        <p>
                          Incorrect email or password
                        </p>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div  class="form-group"  [style]="isPasswordAndCredentialsValid() ? MARGIN_TOP_30 + TRANSITION_05 : TRANSITION_05">
                <div class="row align-items-center custom-row-remember-forget" style="display: flex; justify-content: space-between">
                  <div class="remember-me" >
                    <input type="checkbox" id="rememberMe" name="rememberMe" style="cursor: pointer" (click)="rememberMe()"/>
                    <label class="text-right remember-me-label forgot-link" for="rememberMe" >Remember Me</label>
                  </div>
                  <div class="text-right forgot-password">
                    <a class="forgot-link" routerLink="/login/forgot-password">Forgot Password ?</a>
                  </div>
                </div>
              </div>
              <button class="btn btn-lg btn-block btn-primary login-btn effect-btn btn-login squer-btn"
                      type="submit" [disabled]="isLoginButtonDisabled()">Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
