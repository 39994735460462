import {Injectable} from '@angular/core';
import {HttpService} from "../util/http.service";
import {HandbookEditRequestModel} from "../model/handbook/handbook-edit-request.model";
import {Observable} from "rxjs";
import {HandbookResponseModel} from "../model/handbook/handbook-response.model";
import {environment} from "../../../environments/environment";
import {HandbookSeenModel} from "../model/handbook/handbook-seen.model";

@Injectable({
  providedIn: 'root'
})
export class HandbookService {
  private readonly BASE_PATH: string = environment.apiURL;
  private readonly VERSION: string = environment.version;
  private readonly HANDBOOK_CONTROLLER: string = environment.handbook;
  private readonly GET_HANDBOOK_BY_COMPANY_ID: string = '/company/';
  private readonly HANDBOOK_SEEN: string = '/handbook-seen';

  constructor(private http: HttpService) {
  }

  public edit(handbookEditRequestDto: HandbookEditRequestModel): Observable<HandbookResponseModel> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.HANDBOOK_CONTROLLER}`;
    return this.http.put<HandbookResponseModel>(path, handbookEditRequestDto);
  }

  public getByCompanyId(id: number): Observable<HandbookResponseModel> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.HANDBOOK_CONTROLLER}${this.GET_HANDBOOK_BY_COMPANY_ID}${id}`;
    return this.http.get<HandbookResponseModel>(path);
  }
  public getHandbookSeen(): Observable<HandbookSeenModel> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.HANDBOOK_CONTROLLER}${this.HANDBOOK_SEEN}`;
    return this.http.get<HandbookSeenModel>(path);
  }

  public editHandbookSeen(handbookSeen: HandbookSeenModel): Observable<HandbookSeenModel> {
    const path: string = `${this.BASE_PATH}${this.VERSION}${this.HANDBOOK_CONTROLLER}${this.HANDBOOK_SEEN}`;
    return this.http.put<HandbookSeenModel>(path, handbookSeen);
  }
}
