<div id="container" *ngIf="!readonly; else readonlyTemplate">
  <fa-icon
    [class.rated]="rating >= 5"
    [icon]="faStar"
    (click)="setRating(5)"
  ></fa-icon>
  <fa-icon
    [class.rated]="rating >= 4"
    [icon]="faStar"
    (click)="setRating(4)"
  ></fa-icon>

  <fa-icon
    [class.rated]="rating >= 3"
    [icon]="faStar"
    (click)="setRating(3)"
  ></fa-icon>

  <fa-icon
    [class.rated]="rating >= 2"
    [icon]="faStar"
    (click)="setRating(2)"
  ></fa-icon>

  <fa-icon
    [class.rated]="rating >= 1"
    [icon]="faStar"
    (click)="setRating(1)"
  ></fa-icon>
</div>

<ng-template #readonlyTemplate>
  <div id="readonlyContainer">
    <ng-container *ngFor="let index of [5, 4, 3, 2, 1]">
      <fa-icon
        [class.rated]="rating >= index"
        [icon]="faStar"
      ></fa-icon>
    </ng-container>
  </div>
</ng-template>

