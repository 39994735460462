import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class BadgePageService {
  private readonly CATEGORY_KEY = 'selectedCategory';
  private readonly PAGE_KEY = 'currentPage';
  saveCategory(categoryId:number){
    sessionStorage.setItem(this.CATEGORY_KEY,categoryId.toString());
  }
  getCategory():number{
    return Number(sessionStorage.getItem(this.CATEGORY_KEY))|| 0;
  }
  savePage(categoryId:number,page:number){
    sessionStorage.setItem(`${this.PAGE_KEY}${categoryId}`, page.toString());
  }
  getPage(categoryId: number):number{
    return Number(sessionStorage.getItem(`${this.PAGE_KEY}${categoryId}`)) || 0;
  }
}
