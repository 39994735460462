export class SearchDataModel {
  fieldName: string;
  value: string;
  ids: number[];

  constructor(fieldName: string, value: string, ids: number[]) {
    this.fieldName = fieldName;
    this.value = value;
    this.ids = ids;
  }
}
