<mat-form-field appearance="outline" class="range-picker">
  <mat-label>Date Range</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="today">
    <input matStartDate formControlName="start" placeholder="Start date" class="range-input"/>
    <input matEndDate formControlName="end" placeholder="End date" class="range-input"/>
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker>
    <mat-date-range-picker-actions>
      <button mat-button matDatepickerCancel (click)="onCancel()">Reset</button>
      <button mat-raised-button matDatepickerApply (click)="onApply()">Ok</button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
  <mat-error *ngIf="range.get('end')?.hasError('sameDate')">Start and End dates cannot be the same.</mat-error>
  <mat-error *ngIf="range.get('end')?.hasError('required')">Please select an end date.</mat-error>
</mat-form-field>
