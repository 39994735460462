export class EmployeeDiscordStatisticsRequestModelModel {
  employeeId: number
  username: string;
  startDate: string;
  endDate: string;
  status: string;

  constructor(employeeId: number, username: string, startDate: string, endDate: string, status: string) {
    this.employeeId = employeeId;
    this.username = username;
    this.startDate = startDate;
    this.endDate = endDate;
    this.status = status;
  }
}
