export const config = {

  devApiUrl: "https://dev.hr.itspace.llc/api/",

  prodApiUrl: "https://hr.itspace.llc/api/",

  localApiUrl: "http://localhost:7404/",
};

export const paths = {

  // employee
  getAllEmployees: 'admin/employees',

  // project
  projectController: 'admin/projects',

  // department
  createDepartment: 'admin/departments',
  getAllDepartments: 'admin/departments/all',

  //employee-badge-component
  badgePath: 'badge',
  getAllBadge: '/all',
  byCategory: '?categoryId=',
  allSent: '/all-sent',
  allReceived: '/all-received',
  send: '/send',
  getAllBadgeCategories: '/categories',
  update: '/update',

  // timeoff
  timeoff: 'time-off',

  // document
  files: 'files/',

  // handbook
  handbook: 'admin/handbook',

  // feedback
  feedback: 'feedback',
  searchSkills: '/skill/search',
  requestFeedback: '/request/send',
  sentFeedback: '/by-sender-id',
  receivedFeedback: '/by-receiver-id',

  // note
  notePath: 'employee/note',

  // goal
  goalPath: 'goals'
}
