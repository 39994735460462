import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BadgeService} from "../../../../../core/service/badge.service";
import {BadgeResponseModel} from "../../../../../core/model/badge/badge-response.model";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {EmployeeService} from "../../../../../core/service/employee.service";
import {debounceTime, map, of, ReplaySubject, Subject, switchMap, takeUntil, tap} from "rxjs";
import {EmployeeBadgeRequestModel} from "../../../../../core/model/badge/employee-badge-request.model";
import {filter} from "rxjs/operators";
import {AuthService} from "../../../../../core/service/auth.service";
import {SearchDataModel} from "../../../../../core/model/search/search-data.model";
import {PageableModel} from "../../../../../core/model/pagination/pageable.model";
import {EmployeePageModel} from "../../../../../core/model/employee/employee-page.model";
import {EmployeeResponseForListModel} from "../../../../../core/model/employee/employee-response-for-list.model";


@Component({
  selector: 'app-badge-send-dialog',
  templateUrl: './badge-send-dialog.component.html',
  styleUrls: ['./badge-send-dialog.component.css']
})
export class BadgeSendDialogComponent implements OnInit, OnDestroy {
  public badge: BadgeResponseModel;
  public filteredEmployees: ReplaySubject<EmployeeResponseForListModel[]> = new ReplaySubject<Array<EmployeeResponseForListModel>>(1);
  public searching = false;
  public sendBadgeForm: FormGroup;
  public isSubmitted = false;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<BadgeSendDialogComponent>,
    private badgeService: BadgeService,
    private employeeService: EmployeeService,
    private authService: AuthService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.badge = this.data;
  }

  ngOnInit(): void {
    this.sendBadgeForm = this.fb.group({
      employee: new FormControl(''),
      employeeFilter: new FormControl(''),
      comment: new FormControl(''),
    });
    this.sendBadgeForm.controls["employeeFilter"].valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      takeUntil(this._unsubscribe$),
      debounceTime(200),
      switchMap(value => {
        if (value.length > 0) {
          const searchModel: SearchDataModel = new SearchDataModel('fullName', value, []);
          const pageable: PageableModel = new PageableModel(0);
          return this.employeeService.search(pageable, searchModel);
        }
        return of([])
      })
    ).pipe(
      // @ts-ignore
      map((employees: EmployeePageModel) => {
        const currentUserId: number = this.authService.getCurrentUser().id;
        return employees.content.filter((employee: { id: number; }) => employee.id != currentUserId);
      })
    ).subscribe((filteredEmployees: EmployeeResponseForListModel[]) => {
      this.searching = false;
      this.filteredEmployees.next(filteredEmployees);
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.sendBadgeForm.valid && this.sendBadgeForm.get("employee")?.value["id"]) {
      const sendBudge = new EmployeeBadgeRequestModel();
      sendBudge.badgeId = this.badge.id;
      sendBudge.comment = this.sendBadgeForm.get("comment")?.value || "";
      sendBudge.receiverId = this.sendBadgeForm.get("employee")?.value["id"] || "";
      this.badgeService.sendBadge(sendBudge)
        .subscribe({
          next: () => {
            this.closeDialog();
          }, error: () => {
            this.closeDialog();
          }
        });

    } else {
      this.isSubmitted = false
      this.sendBadgeForm.get("employee")?.setErrors({'nonValidEmployee': true});
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
