import {SafeUrl} from "@angular/platform-browser";
import {Role} from "./role.enum";
import {EmployeeLink} from "./employee-link.model";

export class EmployeeResponseModel {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  jobTitle: string;
  employmentType: string;
  employmentCategory: string;
  startDate: Date;
  level: string;
  lineManagerIds: number[] = [];
  teamLeadIds: number[] = [];
  departmentIds: number[] = [];
  fullName: string;
  birthday: Date;
  location: string;
  profilePicture: string;
  status: boolean;
  bankAccountName: string;
  bankCardName: string;
  bankAccountNumber: string;
  bankCardNumber: string;
  accountNumberName: string;
  cardNumberName: string;
  role: Role;
  profilePictureFile?: SafeUrl;
  links: Array<EmployeeLink> = [];
  discordUsername: string;
  profilePicturePath?: SafeUrl;
}
