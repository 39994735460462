import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BadgeCategoryModel} from "../../../core/model/badge/badge-category.model";
import {BadgeService} from "../../../core/service/badge.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BadgeRequestModel} from "../../../core/model/badge/badge-request.model";
import {BadgeCategoryStatusEnum} from "../../../core/model/badge/badge-category-status.enum";
import {BadgeSendComponent} from "../badge-send/badge-send.component";

@Component({
  selector: 'app-badge-add',
  templateUrl: './badge-add.component.html',
  styleUrls: ['./badge-add.component.css']
})
export class BadgeAddComponent implements OnInit {

  protected categories: BadgeCategoryModel[] = [];
  protected badgeForm: FormGroup;
  protected selectedFile: File;
  protected url: any;
  protected badgeRequest: BadgeRequestModel;
  protected editPopup: boolean;
  protected isSubmitting: boolean = false;

  constructor(private badgeService: BadgeService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<BadgeSendComponent>,
              @Inject(MAT_DIALOG_DATA) protected data: any
  ) {
    if (data.badge) {
      this.editPopup = true;
    }
  }

  ngOnInit() {
    this.loadBadgeCategories();
    this.initializeBadgeForm();
    if (this.editPopup) {
      this.initializeIfSelectedEvent();
    }
  }

  onSubmit() {
    if (this.badgeForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.prepareBadgeRequest();
      const formData = this.createFormData();
      if (this.editPopup) {
        this.updateBadge(formData);
      } else {
        this.createBadge(formData);
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  onFileSelected(event: Event): void {
    this.removeElementById('icon');
    this.removeElementById('badgeImage');
    this.removeElementById('uploadText');

    this.handleFileSelection(event);
  }

  private removeElementById(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.remove();
    }
  }

  private handleFileSelection(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      this.selectedFile = target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }

  private prepareBadgeRequest() {
    this.badgeRequest = new BadgeRequestModel(
      this.badgeForm.value.name,
      this.badgeForm.value.categoryId,
      this.badgeForm.value.badgeRole,
      this.badgeForm.value.description,
    );
  }

  private createFormData(): FormData {
    const formData = new FormData();
    const badgeBlob = new Blob([JSON.stringify(this.badgeRequest)], {type: 'application/json'});
    formData.append('badge', badgeBlob);
    formData.append('imageFile', this.selectedFile);
    return formData;
  }

  private updateBadge(formData: FormData) {
    this.isSubmitting = true
    this.badgeService.update(this.data.badge.id, formData).subscribe({
      next: () => {
        this.dialogRef.close("ok");
      },
      error:() => {
        this.dialogRef.close();
      }
    });
  }

  private createBadge(formData: FormData) {
    if (this.selectedFile) {
      this.isSubmitting = true
      this.badgeService.createBadge(formData).subscribe({
        next: () => {
          this.dialogRef.close("ok");
        },
        error:() => {
          this.dialogRef.close();
        }
      });
    }
  }

  private initializeIfSelectedEvent(): void {
    const controls = this.badgeForm.controls;
    controls["categoryId"].setValue(this.data.badge.categoryId);
    controls["badgeRole"].setValue(this.data.badge.badgeRole);
    this.badgeForm.updateValueAndValidity();
  }

  private loadBadgeCategories(): void {
    this.badgeService.getAllBadgeCategories(BadgeCategoryStatusEnum.ALL).subscribe({
      next: (categories: BadgeCategoryModel[]) => {
        this.categories = categories;
      }
    });
  }

  private initializeBadgeForm(): void {
    this.badgeForm = this.fb.group({
      name: [this.data.badge ? this.data.badge.name : '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(50)]
      ],
      categoryId: [this.data.badge ? this.data.categoryId : '',
        [Validators.required]
      ],
      badgeRole: ['', [Validators.required]],
      description: [this.data.badge ? this.data.badge.description : '',
        [Validators.required, Validators.minLength(50), Validators.maxLength(900)]
      ]
    });
  }
}
