import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {HandbookService} from "../../../core/service/handbook.service";
import {HandbookSeenModel} from "../../../core/model/handbook/handbook-seen.model";
import {HandbookTabs} from "../../../core/model/handbook/handbook-tabs.enum";
import {AuthService} from "../../../core/service/auth.service";
import {Role} from "../../../core/model/employee/role.enum";

@Component({
  selector: 'app-handbook-tabs',
  templateUrl: './handbook-tabs.component.html',
  styleUrls: ['./handbook-tabs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandbookTabsComponent implements OnInit {
  handbookSeen: HandbookSeenModel;
  tabs: { name: string, enumValue: HandbookTabs, isSeen: boolean }[] = [];
  currentUser: any

  @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();

  protected readonly Role = Role;

  constructor(
    private router: Router,
    private handbookService: HandbookService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser()
    this.getHandbookSeen();
  }

  getHandbookSeen(): void {
    this.handbookService.getHandbookSeen().subscribe(data => {
      this.handbookSeen = data;
      this.initializeTabs();
    });
  }

  initializeTabs(): void {
    if (!this.handbookSeen) {
      return;
    }
    this.tabs = [
      { name: 'welcome', enumValue: HandbookTabs.IS_WELCOME_SEEN, isSeen: this.handbookSeen.isWelcomeSeen },
      { name: 'corporate-policies', enumValue: HandbookTabs.IS_CORPORATE_POLICIES_SEEN, isSeen: this.handbookSeen.isCorporatePoliciesSeen },
      { name: 'attendance-policies', enumValue: HandbookTabs.IS_ATTENDANCE_POLICIES_SEEN, isSeen: this.handbookSeen.isAttendancePoliciesSeen },
      { name: 'learning-resources', enumValue: HandbookTabs.IS_LEARNING_RESOURCES_SEEN, isSeen: this.handbookSeen.isLearningResourcesSeen },
    ];
    this.cdr.detectChanges();
  }

  public onClickTab(tab: { name: string, enumValue: HandbookTabs }): void {
    this.router.navigateByUrl(`handbook/${tab.name}`).then().catch();
    this.onTabChange.emit(tab.name);
    this.updateHandbookSeen(tab.enumValue);
  }

  private updateHandbookSeen(field: HandbookTabs): void {
    switch (field) {
      case HandbookTabs.IS_WELCOME_SEEN:
        this.handbookSeen.isWelcomeSeen = true;
        break;
      case HandbookTabs.IS_CORPORATE_POLICIES_SEEN:
        this.handbookSeen.isCorporatePoliciesSeen = true;
        break;
      case HandbookTabs.IS_ATTENDANCE_POLICIES_SEEN:
        this.handbookSeen.isAttendancePoliciesSeen = true;
        break;
      case HandbookTabs.IS_LEARNING_RESOURCES_SEEN:
        this.handbookSeen.isLearningResourcesSeen = true;
        break;
      default:
        return;
    }
    let currentUser = this.authService.getCurrentUser();
    currentUser.handbookSeen = this.handbookSeen
    this.handbookService.editHandbookSeen(currentUser.handbookSeen).subscribe(() => {
      this.getHandbookSeen();
    });
  }

  public isTabActive(tabName: string): boolean {
    return this.router.url.includes(tabName);
  }
}
