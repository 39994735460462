<div class="modal-lg customize_popup">
  <form [formGroup]="badgeForm" (ngSubmit)="onSubmit()">
    <div class="modal-content" style="border-bottom: none;">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabelevent">
          {{ editPopup ? 'Edit Badge' : 'Add New Badge' }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" mat-dialog-close>
          <span aria-hidden="true" (click)="close()">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="justify-content-center row central-div">
          <mat-form-field class="mat-form-field" appearance="outline" style="width: 48%">
            <mat-label class="label">Name</mat-label>
            <input matInput type="text" appNoWhitespace formControlName="name" autocomplete="off"/>
            <mat-error *ngIf="badgeForm.get('name')?.hasError('required')">Name is required</mat-error>
            <mat-error *ngIf="badgeForm.get('name')?.hasError('minlength')">Name must be at least 3 characters long
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field" appearance="outline" style="width: 48%">
            <mat-label>Category</mat-label>
            <mat-select formControlName="categoryId" id="category">
              <mat-option *ngFor="let category of categories"
                          [value]="category.id">{{ category.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="badgeForm.get('categoryId')?.hasError('required')">Category is required</mat-error>
          </mat-form-field>
        </div>

        <div class="justify-content-center row">
          <mat-form-field class="mat-form-field" appearance="outline">
            <mat-label>Level</mat-label>
            <mat-select formControlName="badgeRole" id="badgeRole">
              <mat-option value="STANDARD">Standard</mat-option>
              <mat-option value="LEAD">Lead</mat-option>
              <mat-option value="CEO">CEO</mat-option>
            </mat-select>
            <mat-error *ngIf="badgeForm.get('badgeRole')?.hasError('required')">Level is required</mat-error>
          </mat-form-field>
        </div>

        <div class="justify-content-center row">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label class="label">Description</mat-label>
            <textarea class="comment-area" matInput formControlName="description"></textarea>
            <mat-error *ngIf="badgeForm.get('description')?.hasError('required')">Description is required</mat-error>
            <mat-error *ngIf="badgeForm.get('description')?.hasError('maxlength')">Description cannot exceed 900
              characters
            </mat-error>
            <mat-error *ngIf="badgeForm.get('description')?.hasError('minlength')">Description can not be less then 50
              characters
            </mat-error>
          </mat-form-field>
        </div>

        <label for="input-file" id="drop-area">
          <input type="file" id="input-file" accept="image/*" (change)="onFileSelected($event)" hidden="hidden">
          <div id="img-view">
            <i *ngIf="!editPopup" class="fa-solid fa-cloud-arrow-up fa-sm" id="icon" style="color: #d9d9d9"></i>
            <img [src]="url">
            <img id="badgeImage" *ngIf="editPopup" [src]="this.data.badge.id | badgeImage | async">
            <p id="uploadText" *ngIf="!editPopup">Click here to upload image</p>
          </div>
        </label>

      </div>
      <div class="modal-footer">
        <button mat-dialog-close type="button"
                class="effect-btn btn-effect-custom btn-danger-custom squer-btn"
                data-dismiss="modal" (click)="close()">Cancel
        </button>
        <button type="submit"
                class="effect-btn btn-effect-custom btn-success-custom squer-btn"
                [disabled]="isSubmitting"
                (click)="onSubmit()">{{ editPopup ? 'Save' : 'Add' }}
        </button>
      </div>
    </div>
  </form>
</div>
