import {Injectable} from '@angular/core';
import {DatePipe} from "@angular/common";
import {from} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  private readonly DEFAULT_FORMAT = 'dd-MM-yyyy';
  private readonly DATE_TIME_FORMAT = 'dd MMMM yyyy | HH:mm';
  private readonly DATE_FORMAT = 'dd MMMM yyyy';

  constructor(private datePipe: DatePipe) {
  }

  public getFormattedDate(date: Date, format?: string) {
    const formatString = format ? format : this.DEFAULT_FORMAT;
    const formatDate = this.datePipe.transform(date, formatString);
    if (typeof formatDate === 'string') {
      return formatDate;
    }else {
      return "";
    }
  }

  transformDate(startDate: Date, endDate: Date): string | null {
    let verifiedStartDate = new Date(startDate);
    let verifiedEndDate = new Date(endDate);

    const transformStart = this.getFormattedDate(verifiedStartDate, this.DATE_TIME_FORMAT);
    const transformEnd = this.getFormattedDate(verifiedEndDate, this.DATE_TIME_FORMAT);

    if (!transformStart || !transformEnd) {
      return null;
    }

    if (this.isOneDay(startDate, endDate)) {
      const transformStartWithoutTime = this.getFormattedDate(verifiedStartDate, this.DATE_FORMAT);
      return `${transformStartWithoutTime}`;
    }

    return `${transformStart} - ${transformEnd}`;
  }

  formatFullDayRange(startDate: Date, endDate: Date): string | null {
    const verifiedStartDate = new Date(startDate);
    const verifiedEndDate = new Date(endDate);

    if (this.isOneDay(verifiedStartDate, verifiedEndDate)) {
      const transformStartWithoutTime = this.getFormattedDate(verifiedStartDate, this.DATE_FORMAT);
      return `${transformStartWithoutTime}`;
    }
    else {
      const transformStart = this.getFormattedDate(verifiedStartDate, this.DATE_FORMAT);
      const transformEnd = this.getFormattedDate(verifiedEndDate, this.DATE_FORMAT);

      return `${transformStart} - ${transformEnd}`;
    }
  }

  transformDateForToday(startDate: Date, endDate: Date, isAllDay: boolean): string | null {
    let verifiedStartDate = new Date(startDate);
    let verifiedEndDate = new Date(endDate);

    const startMonth = verifiedStartDate.toLocaleString('en-US', {month: 'long'});
    const endMonth = verifiedEndDate.toLocaleString('en-US', {month: 'long'});
    const startDay = verifiedStartDate.getDate();
    const endDay = verifiedEndDate.getDate();

    const startHours = verifiedStartDate.getHours().toString().padStart(2, '0');
    const startMinutes = verifiedStartDate.getMinutes().toString().padStart(2, '0');
    const endHours = verifiedEndDate.getHours().toString().padStart(2, '0');
    const endMinutes = verifiedEndDate.getMinutes().toString().padStart(2, '0');

    if (isAllDay) {
      return this.formatAllDayEvent(startMonth, startDay, endMonth, endDay);
    }
    return this.formatTimedEvent(startMonth, startDay, endMonth, endDay, startHours, startMinutes, endHours, endMinutes);
  }


  transformBirthday(startDate: Date): string {
    let verifiedStartDate = new Date(startDate);

    const startMonth = verifiedStartDate.toLocaleString('en-US', {month: 'long'});
    const startDay = verifiedStartDate.getDate();

    return `${startMonth} ${startDay}`;
  }

  private isOneDay(first: Date, second: Date): boolean {
    const firstDate = new Date(first);
    const secondDate = new Date(second);

    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  }



  private formatAllDayEvent(startMonth: string, startDay: number, endMonth: string, endDay: number) {
    if (startMonth === endMonth) {
      return startDay === endDay ? `${startMonth} ${startDay}`
        : `${startMonth} ${startDay} - ${endDay}`;
    }
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }


  private formatTimedEvent(startMonth: string, startDay: number, endMonth: string, endDay: number, startHours: string, startMinutes: string, endHours: string, endMinutes: string) {
    if (startMonth === endMonth) {
      return startDay === endDay
        ? `${startMonth} ${startDay}, ${startHours}:${startMinutes} - ${endHours}:${endMinutes}`
        : `${startMonth} ${startDay}, ${startHours}:${startMinutes} - ${endDay}, ${endHours}:${endMinutes}`;
    }
    return `${startMonth} ${startDay}, ${startHours}:${startMinutes} - ${endMonth} ${endDay}, ${endHours}:${endMinutes}`;
  }
}
