import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {EmployeeBadgeResponseModel} from "../../../core/model/badge/employee-badge-response.model";
import {Subject} from "rxjs";
import {AuthService} from "../../../core/service/auth.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BadgeSendDialogComponent} from "../badge-send/popup/badge-send-dialog/badge-send-dialog.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-badge-list-view',
  templateUrl: './badge-list-view.component.html',
  styleUrls: ['./badge-list-view.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BadgeListViewComponent implements OnInit {
  @Input() employeeBadge: EmployeeBadgeResponseModel = new EmployeeBadgeResponseModel();

  @Input() isReceivedBadge = false;

  currentUser: any;

  private _unsubscribe$: Subject<void> = new Subject();
  defaultImage: string = 'assets\\img\\user.jpg';

  constructor(private authService: AuthService,
              private dialogRef: MatDialogRef<BadgeSendDialogComponent>,
              protected router: Router,
              @Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  navigateUserProfile(id: number) {
    this.dialogRef.close();
    this.router.navigate([`/profile/${id}/personal`])
  }
}
