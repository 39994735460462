import {Component} from '@angular/core';

@Component({
  selector: 'app-breadcrumb-home',
  templateUrl: './breadcrumb-home.component.html',
  styleUrls: ['./breadcrumb-home.component.css']
})
export class BreadcrumbHomeComponent {

}
