import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {TimeOffResponseModel} from '../model/time-off/time-off-response.model';
import * as moment from 'moment';
import {TimeOffCategory} from "../model/time-off/time-off-category.enum";

export function timeOffOverlapValidator(existingTimeOffs: () => TimeOffResponseModel[], currentUserId: number, requestId: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const timeOffs = existingTimeOffs();
    const startDateTime: Date = control.get('startDateTime')?.value;
    const endDateTime: Date = control.get('endDateTime')?.value;
    const isAllDay: boolean = control.get('isAllDay')?.value ?? false;
    const timeOffCategory: string = control.get('timeOffCategory')?.value;

    const newStart = moment(startDateTime);
    const newEnd = moment(endDateTime);

    if (!isAllDay) {
      if (new Date(startDateTime).getTime() == new Date(endDateTime).getTime() || newStart.isAfter(newEnd) ) {
        control.get('startDateTime')?.setErrors({
          ...(control.get('startDateTime')?.errors || {}),
          'startAfterEnd': true
        });
        control.get('endDateTime')?.setErrors({...(control.get('endDateTime')?.errors || {}), 'startAfterEnd': true});
        return {'startAfterEnd': true};
      }
    }

    if (isAllDay && newStart.isAfter(newEnd)) {
      control.get('endDateTime')?.setErrors({...(control.get('endDateTime')?.errors || {}), 'startAfterEnd': true});
      return {'startAfterEnd': true};
    }

    let overlapError = false;
    for (let timeOff of timeOffs) {
      const existingStart = moment(timeOff.startDateTime);
      const existingEnd = moment(timeOff.endDateTime);

      if ((timeOff.requestId !== requestId && timeOff.employee.id === currentUserId) &&
        ((newStart.isBetween(existingStart, existingEnd, null, '[]') ||
        newEnd.isBetween(existingStart, existingEnd, null, '[]') ||
        existingStart.isBetween(newStart, newEnd, null, '[]') ||
        existingEnd.isBetween(newStart, newEnd, null, '[]')) && timeOffCategory !== TimeOffCategory.WORKING_REMOTELY)
      ) {
        overlapError = true;
        break;
      }
    }

    if (overlapError) {
      control.get('startDateTime')?.setErrors({...(control.get('startDateTime')?.errors || {}), 'dateOverlap': true});
      control.get('endDateTime')?.setErrors({...(control.get('endDateTime')?.errors || {}), 'dateOverlap': true});
      return {'dateOverlap': true};
    }

    const startDateTimeErrors = control.get('startDateTime')?.errors || {};
    const endDateTimeErrors = control.get('endDateTime')?.errors || {};
    if (startDateTimeErrors['startAfterEnd']) delete startDateTimeErrors['startAfterEnd'];
    if (endDateTimeErrors['startAfterEnd']) delete endDateTimeErrors['startAfterEnd'];
    if (startDateTimeErrors['dateOverlap']) delete startDateTimeErrors['dateOverlap'];
    if (endDateTimeErrors['dateOverlap']) delete endDateTimeErrors['dateOverlap'];

    if (Object.keys(startDateTimeErrors).length === 0) control.get('startDateTime')?.setErrors(null);
    else control.get('startDateTime')?.setErrors(startDateTimeErrors);

    if (Object.keys(endDateTimeErrors).length === 0) control.get('endDateTime')?.setErrors(null);
    else control.get('endDateTime')?.setErrors(endDateTimeErrors);

    return null;
  };
}
