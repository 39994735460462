import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'keyValue'
})
export class KeyValuePipe implements PipeTransform {

  transform(key: string, map: { [key: string]: string }): string {
    return map[key] || '';
  }

}
