import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {TimeOffStatusCountModel} from "../model/time-off/time-off-status-count.model";
import {HttpService} from "../util/http.service";
import {TimeOffResponseModel} from "../model/time-off/time-off-response.model";
import {TimeOffRequestModel} from "../model/time-off/time-off-request.model";
import {TimeOffPageModel} from "../model/time-off/time_off_page.model";
import {PageableModel} from "../model/pagination/pageable.model";
import {HttpParams} from "@angular/common/http";
import {RequestStatus} from "../model/time-off/requestStatus.enum";
import {SortDirection} from "../model/employee/sort-direction";

@Injectable({
  providedIn: 'root'
})
export class TimeOffService {
  private readonly BASE_PATH: string = environment.apiURL;

  private readonly TIME_OFF_CONTROLLER_FULL_PATH: string = `${this.BASE_PATH}${environment.version}${environment.timeoff}`;

  private readonly GET_UPCOMING_TIME_OFFS: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/upcoming/`;
  private readonly GET_HISTORY_TIME_OFFS: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/history/`;
  private readonly GET_MANAGER_TIME_OFFS: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/manager`;
  private readonly GET_TIME_OFFS_STATUSES: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/status`;
  private readonly GET_ALL_BY_DURATION: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/duration`;

  constructor(private http: HttpService) {
  }

  public getStatusCount(id: number): Observable<TimeOffStatusCountModel> {
    const path: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/status-count/${id}`;
    return this.http.get(path);
  }

  public create(body: TimeOffRequestModel): Observable<TimeOffResponseModel> {
    const path: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}`;
    return this.http.post<TimeOffResponseModel>(path, body);
  }

  public edit(body: TimeOffRequestModel): Observable<TimeOffResponseModel> {
    const path: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}`;
    return this.http.put<TimeOffResponseModel>(path, body);
  }

  public getAllByManagerId(managerId: number, pageable: PageableModel, employeeId?: number, sortColumn?: string, sortDirection?: string): Observable<TimeOffPageModel> {
    const path: string = `${this.GET_MANAGER_TIME_OFFS}/${managerId}`;
    let param = new HttpParams()
      .set("page", pageable.page)
      .set("size", pageable.size);
    if (sortColumn && sortDirection) {
      param = param
        .set("sort", `${sortColumn},${sortDirection}`);
    }
    if (employeeId) {
      param = param
        .set("employeeId", employeeId);
    }
    return this.http.get<TimeOffPageModel>(path, param);
  }


  public getAllByEmployeeId(employeeId: number, fromDate: string, toDate: string): Observable<Array<TimeOffResponseModel>> {
    const path: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/${employeeId}/${fromDate}/${toDate}`;
    return this.http.get<Array<TimeOffResponseModel>>(path);
  }

  public getAllTimeOffs(employeeId: number, page: number, size: number, sortColumn: string, sortDirection: SortDirection, status?: RequestStatus | null): Observable<TimeOffPageModel> {
    const path: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/all/${employeeId}`;
    let param = new HttpParams()
      .set("page", page)
      .set("size", size)
      .set("sort", `${sortColumn},${sortDirection}`);
    if (status) {
      param = param
        .set("status", status);
    }
    return this.http.get<TimeOffPageModel>(path, param);
  }

  public getUpcomingTimeOffs(id: number, page: number, size: number, sortColumn: string, sortDirection: SortDirection, date: string, status?: RequestStatus | null): Observable<TimeOffPageModel> {
    const path = `${this.GET_UPCOMING_TIME_OFFS}${id}`;
    let param = new HttpParams()
      .set("page", page)
      .set("size", size)
      .set("sort", `${sortColumn},${sortDirection}`)
      .set("date", date);
    if (status) {
      param = param
        .set("status", status);
    }
    return this.http.get<TimeOffPageModel>(path, param);
  }

  public getHistoryTimeOffs(id: number, page: number, size: number, sortColumn: string, sortDirection: SortDirection, date: string, status?: RequestStatus | null): Observable<TimeOffPageModel> {
    const path = `${this.GET_HISTORY_TIME_OFFS}${id}`;
    let param = new HttpParams()
      .set("page", page)
      .set("size", size)
      .set("sort", `${sortColumn},${sortDirection}`)
      .set("date", date);
    if (status) {
      param = param
        .set("status", status);
    }
    return this.http.get<TimeOffPageModel>(path, param);
  }

  public getTimeOffByDuration(fromDate: string,toDate: string,pageable: PageableModel): Observable<TimeOffPageModel> {
    const path: string = `${this.GET_ALL_BY_DURATION}/${fromDate}/${toDate}`;
    let param = new HttpParams()
      .set('page', pageable.page)
      .set('size', pageable.size);
    return this.http.get<TimeOffPageModel>(path, param);
  }

  public delete(requestId: number): Observable<void> {
    const path: string = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/${requestId}`;
    return this.http.delete<void>(path);
  }

  public approveTimeOff(requestId: number, currentUserId: number): Observable<void> {
    const path = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/approve`;
    const param = new HttpParams()
      .set("requestId", requestId)
      .set("managerId", currentUserId);
    return this.http.get<void>(path, param);
  }

  public rejectTimeOff(requestId: number, currentUserId: number): Observable<void> {
    const path = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/reject`;
    const param = new HttpParams()
      .set("requestId", requestId)
      .set("managerId", currentUserId);
    return this.http.get<void>(path, param);
  }

  public getAllByStatus(status: RequestStatus, fromDate: string, toDate: string): Observable<Array<TimeOffResponseModel>> {
    const path = `${this.GET_TIME_OFFS_STATUSES}/${status}/${fromDate}/${toDate}`;
    return this.http.get<Array<TimeOffResponseModel>>(path);
  }

  getPendingRequestsCount(): Observable<number> {
    const path = `${this.TIME_OFF_CONTROLLER_FULL_PATH}/pending-count`
    return this.http.get<number>(path);
  }

}
