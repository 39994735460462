import {Component, forwardRef, Input} from '@angular/core';
import {faStar} from '@fortawesome/free-solid-svg-icons/faStar';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true
    }
  ]
})
export class StarRatingComponent implements ControlValueAccessor {
  faStar: IconDefinition = faStar;

  @Input() readonly: boolean = false;
  @Input() rating: number = 0;

  private onChange: (rating: number) => void = () => {
  };
  private onTouched: () => void = () => {
  };

  setRating(value: number) {
    if (this.readonly) return;
    this.rating = value;
    this.onChange(this.rating);
    this.onTouched();
  }

  writeValue(rating: number): void {
    this.rating = rating;
  }

  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }
}
