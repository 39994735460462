<ul class="d-flex justify-content-start flex-wrap p-3">
  <li class="align-items-center mr-3" *ngFor="let employeeBadge of employeeBadgeList">
    <div class="badge-container position-relative">
      <img class="img-fluid badge-img" [src]="employeeBadge.badge.id | badgeImage | async"
           [alt]="employeeBadge.badge.name">
      <div *ngIf="employeeBadge.badge.isArchived" class="lock-overlay position-absolute">
      </div>
    </div>
    <h5 class="text-center" *ngIf="isReceivedBadge; else sentBadge">
      by {{ employeeBadge.sender.fullName }}
    </h5>
    <ng-template #sentBadge>
      <h5 class="text-center">to {{ employeeBadge.receiver.fullName }}</h5>
    </ng-template>
  </li>
</ul>
