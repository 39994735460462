import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appNoWhitespace]',
  providers: [{provide: NG_VALIDATORS, useExisting: NoWhitespaceDirective, multi: true}]
})
export class NoWhitespaceDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim() === '') {
      return { 'whitespace': true };
    }
    return null;
  }
}
