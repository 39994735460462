<div class="modal-lg">

  <div class="modal-content content-border">
    <div class="modal-header">
      <div class="container d-flex align-items-center">
        <h3 class="modal-title mb-1">{{ data.badge.name }}</h3>
        <div class="mb-1 archive-text pt-1 pl-1" *ngIf="data.badge.isArchived">(Archived)</div>
        <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

      <div class="col-md-12 p-3">
        <div class="container mb-3">
          <div class="d-flex justify-content-center">
            <img class="img-fluid badge-img badge-give-img"
                 [src]="data.badge.id | badgeImage | async" [alt]="data.name">
          </div>
          <div class="d-flex align-items-center mt-1">
            <img class="badge-img-sender"
                 [src]="data.sender ? (data.sender | userImage | async) : defaultImage">
            <div>
            </div>
            <div class="d-flex flex-column m-sm-3 justify-content-start">
              <span class="fw-bold from-text">From:</span>
              <span class="badge-text-fullname full-name-to"
                    (click)="navigateUserProfile(data.sender.id)">
                {{ data.sender.fullName }}
                          </span>
            </div>
            <div class="d-flex flex-column justify-content-end date-text-size ml-auto pr-3">
              <span class="fw-bold from-text">Date:</span>
              <span class="badge-text">{{ data.createdAt | date:'yyyy MMMM d' }}</span>
            </div>
          </div>
          <div class="fw-bold text-break from-text mt-1 p-1"><p>{{ data.comment }}</p></div>
        </div>
      </div>
    </div>
</div>


