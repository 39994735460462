import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, DatePipe} from '@angular/common';

import {AppComponent} from './app.component';
import {LoginComponent} from './views/login/login.component';
import {ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MAT_DATETIME_FORMATS} from "@mat-datetimepicker/core";
import {AuthService} from "./core/service/auth.service";

import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter,} from "@angular-material-components/datetime-picker";
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentAdapter} from "@angular-material-components/moment-adapter";
import {NoopScrollStrategy} from "@angular/cdk/overlay";
import {GlobalHttpErrorHandler} from "./core/exception/error-handler.error";
import {CookieService} from "ngx-cookie-service";
import {SharedModule} from "./shared/shared.module";
import {MatMomentDatetimeModule} from "@mat-datetimepicker/moment";
import {UserImagePipe} from "./shared/pipes/user-image.pipe";

export const CUSTOM_MOMENT_DATETIME_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY HH:mm',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

export const CUSTOM_MOMENT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    popupHeaderDateLabel: 'ddd, DD MMM',
  }
};

export const CUSTOM_MAT_DIALOG_DEFAULT_OPTIONS: MatDialogConfig = {
  scrollStrategy: new NoopScrollStrategy()
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatMomentDatetimeModule
  ],

  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpErrorHandler, multi: true},

    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_DATETIME_FORMATS},
    {provide: MAT_DATETIME_FORMATS, useValue: CUSTOM_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_DATE_FORMATS},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: CUSTOM_MAT_DIALOG_DEFAULT_OPTIONS},

    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
    {provide: AuthService},
    {provide: DatePipe},
    {provide: UserImagePipe},

    [CookieService]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
