<div class="row">
  <div class="col-xl-12 col-sm-12 col-12 mb-4">
    <div class="head-link-set">
      <ul *ngIf="currentUser.role !== Role.ADMIN">
        <li *ngFor="let tab of tabs" class="icon-wrapper position-relative">
          <a [ngClass]="isTabActive(tab.name) ? 'active' : ''" (click)="onClickTab(tab)">
            {{ tab.name | titlecase }}
          </a>
          <span *ngIf="!tab.isSeen" class="icon-red"></span>
        </li>
      </ul>
      <ul *ngIf="currentUser.role === Role.ADMIN">
        <li class="icon-wrapper">
          <a (click) = "onTabChange.emit('welcome')" [ngClass]="isTabActive('welcome') ? 'active' : ''" routerLink="/handbook/welcome" class="welcome-link" id="welcome-link">
            {{ 'welcome' | titlecase }}
          </a>
        </li>
        <li class="icon-wrapper">
          <a (click) = "onTabChange.emit('corporate-policies')" [ngClass]="isTabActive('corporate-policies') ? 'active' : ''" routerLink="/handbook/corporate-policies">
            {{ 'corporate-policies' | titlecase }}
          </a>
        </li>
        <li class="icon-wrapper">
          <a (click) = "onTabChange.emit('attendance-policies')" [ngClass]="isTabActive('attendance-policies') ? 'active' : ''" routerLink="/handbook/attendance-policies">
            {{ 'attendance-policies' | titlecase }}
          </a>
        </li>
        <li class="icon-wrapper">
          <a (click) = "onTabChange.emit('learning-resources')" [ngClass]="isTabActive('learning-resources') ? 'active' : ''" routerLink="/handbook/learning-resources">
            {{ 'learning-resources' | titlecase }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
