import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {map, Observable, of} from "rxjs";
import {AuthResponseModel} from "../model/auth/auth-response.model";
import {EmployeeResponseModel} from "../model/employee/employee-response.model";
import {LoginCredentialsModel} from "../model/auth/login-credentials.model";
import {EmployeeVerifyModel} from "../model/auth/employee-verify.model";
import {Role} from "../model/employee/role.enum";
import {EmployeeForgotPasswordResponseModel} from "../model/employee/employee-forgot-password-response.model";
import {EmailRequestModel} from "../model/mail/email-request.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private logoutChannel = new BroadcastChannel('logout-channel');

  private readonly BASE_PATH = environment.apiURL;
  private readonly VERSION = environment.version;

  constructor(
    private http: HttpClient,
    private route: Router,
  ) {
    const logoutChannel = new BroadcastChannel('logout-channel');
    logoutChannel.onmessage = () => {
      const sessionUser = sessionStorage.getItem('currentUser');
      const localUser = localStorage.getItem('currentUser');
      if (sessionUser && localUser && sessionUser === localUser) {
        sessionStorage.clear();
        location.reload();
      }
    };
  }

  auth(credentialsDto: LoginCredentialsModel, rememberMe: boolean): Observable<AuthResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}login?remember-me=${rememberMe}`;
    return this.http.post<AuthResponseModel>(path, credentialsDto);
  }

  verify(verifyDto: EmployeeVerifyModel): Observable<AuthResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}verify`;
    return this.http.post<AuthResponseModel>(path, verifyDto);
  }

  changePassword(verifyDto: EmployeeVerifyModel): Observable<void> {
    const path = `${this.BASE_PATH}${this.VERSION}change-password`;
    return this.http.post<void>(path, verifyDto);
  }

  sendForgetPasswordVerifyCode(emailRequestModel: EmailRequestModel): Observable<void> {
    const path = `${this.BASE_PATH}${this.VERSION}send-verify-code`;
    return this.http.post<void>(path, emailRequestModel);
  }

  checkVerifyCode(code: any): Observable<boolean> {
    const path = `${this.BASE_PATH}${this.VERSION}check-code`;
    return this.http.post<boolean>(path, code);
  }

  setForgotPasswordToken(emailRequestModel: EmailRequestModel): Observable<EmployeeForgotPasswordResponseModel> {
    const path = `${this.BASE_PATH}${this.VERSION}set-forgot-password-token`;
    return this.http.post<EmployeeForgotPasswordResponseModel>(path, emailRequestModel);
  }

  isVerifiedByEmail(requestDto: EmailRequestModel): Observable<boolean> {
    let path: string = `${this.BASE_PATH}${this.VERSION}is-verified`;
    return this.http.post<boolean>(path, requestDto);
  }

  saveToken(employee: AuthResponseModel, remember: boolean) {
    sessionStorage.setItem('token', employee.token)
    sessionStorage.setItem('currentUser', JSON.stringify(employee))
    if(remember){
      localStorage.setItem('token', employee.token);
      localStorage.setItem('currentUser', JSON.stringify(employee));
    }
    sessionStorage.removeItem('loggedOut');
  }

  getToken(): string | null {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
  }

  logout(): void {
    const sessionUser = sessionStorage.getItem('currentUser')
    const localUser = localStorage.getItem('currentUser')
    if (localUser === sessionUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      this.logoutChannel.postMessage('logout');
    }
    sessionStorage.clear();
    sessionStorage.setItem('loggedOut', 'true');
    this.route.navigate(['login']).catch();
  }

  autoLogin(): Observable<boolean> {
    if (sessionStorage.getItem('loggedOut')) {
      return of(false);
    }
    let token = this.getToken();
    if (token === null) {
      return of(false);
    }
    return this.isTokenExpired(token).pipe(
      map((isExpired) => !isExpired)
    );
  }

  isTokenExpired(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.BASE_PATH}${this.VERSION}is-token-expired?token=${token}`);
  }

  getCurrentUser() {
    const userJson = sessionStorage.getItem('currentUser') || localStorage.getItem('currentUser');
    return userJson ? JSON.parse(userJson) : null;
  }

  isLoggedIn(): boolean {
    return !!(this.getToken());
  }

  updateCurrentUserProfilePicture(pic: string) {
    let currentUser: EmployeeResponseModel = this.getCurrentUser();
    currentUser.profilePicture = pic;
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  setCurrentUser(currentUser: AuthResponseModel): void {
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getCurrentUserRole(): Role {
    return this.getCurrentUser().role;
  }
}
